/* eslint-disable no-nested-ternary */
import { ComponentPropsWithoutRef } from 'react';
import Stack from '@mui/material/Stack';
import { OutboundProcess } from '@features/supplyChain/types';
import { useAuth } from '@context';
import { UserRoleService, User } from '@services';
import { BillingCycle, Invoice } from '@views/Invoice/invoiceTypes';
import { Text } from '@components/Text';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';
import { getNoticeColors } from '@views/Noticeboard/utils';
import {
  getBillingCycleCost,
  getBillingCycleVatCost,
} from '@views/Invoice/invoiceHelper';

interface InvoiceCardProps extends ComponentPropsWithoutRef<'div'> {
  billingCycle: BillingCycle;
  invoice: Invoice;
  user: User | null;
  isWarehouseManager: (user: User | null) => boolean;
  isOverdue: boolean;
  daysOverdue: number;
}

interface PaymentCardProps extends ComponentPropsWithoutRef<'div'> {
  billingCycle: BillingCycle;
  invoice: Invoice;
  user: User | null;
  isWarehouseManager: (user: User | null) => boolean;
}

const calculateDaysUntilDue = (dueDate: string) => {
  const today = dayjs();
  const due = dayjs(dueDate);
  return today.diff(due, 'day');
};

function InvoiceCard({
  billingCycle,
  invoice,
  user,
  isWarehouseManager,
  isOverdue,
  daysOverdue,
}: InvoiceCardProps) {
  const calculateDueSoon = (dueDate: string) => {
    const daysUntilDue = calculateDaysUntilDue(dueDate);
    const isDueSoon = daysUntilDue >= -5;
    return { isDueSoon };
  };

  const { isDueSoon } = calculateDueSoon(invoice.dueDate);

  const shipperInvoiceNumberParts = invoice.invoiceNumber.split('-');
  const shipperInvoiceNumberSnippet = [
    shipperInvoiceNumberParts[1],
    ...shipperInvoiceNumberParts.slice(-2),
  ].join('-');

  return (
    <>
      {invoice.approvedAt ? (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Invoice:</Text>
          <Text size='2xs'>
            {isWarehouseManager(user)
              ? invoice.invoiceNumber.split('-').slice(-3).join('-')
              : shipperInvoiceNumberSnippet}
            {isOverdue ? (
              <ErrorIcon
                sx={{
                  width: 16,
                  height: 16,
                  fill: getNoticeColors('error').fill,
                }}
              />
            ) : isDueSoon ? (
              <ErrorIcon
                sx={{
                  width: 16,
                  height: 16,
                  fill: getNoticeColors('warning').fill,
                }}
              />
            ) : (
              <CheckCircleIcon
                sx={{ width: 16, height: 16, fill: 'var(--colors-green9)' }}
              />
            )}
          </Text>
        </Stack>
      ) : (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>PI:</Text>
          <Text size='2xs'>
            {invoice.invoiceNumber.split('-').slice(-3).join('-')}
            <ErrorIcon
              sx={{
                width: 16,
                height: 16,
                fill: getNoticeColors('warning').fill,
              }}
            />
          </Text>
        </Stack>
      )}
      {isWarehouseManager(user) ? (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Customer:</Text>
          <Text size='2xs'>{billingCycle.shipperBusinessName}</Text>
        </Stack>
      ) : (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Supplier:</Text>
          <Text size='2xs'>{billingCycle.lspBusinessName}</Text>
        </Stack>
      )}
      {isWarehouseManager(user) && !invoice.approvedAt && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>PI generated:</Text>
          <Text size='2xs'>
            {dayjs(invoice.createdAt).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      )}
      {invoice.approvedAt && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Invoice confirmed:</Text>
          <Text size='2xs'>
            {dayjs(invoice.approvedAt).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      )}
      {invoice.dueDate && !invoice.paidAt && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>
            {isOverdue ? 'Payment overdue:' : 'Payment due:'}
          </Text>
          <Text size='2xs'>
            {isOverdue
              ? `${daysOverdue} days`
              : dayjs(invoice.dueDate).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      )}
      {invoice.paidAt && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Paid date:</Text>
          <Text size='2xs'>{dayjs(invoice.paidAt).format('DD/MM/YYYY')}</Text>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>Invoice value:</Text>
        <Text size='2xs' weight='bold'>
          {invoice.lines[0].currencySymbol}{' '}
          {(
            getBillingCycleCost(billingCycle) +
            getBillingCycleVatCost(billingCycle)
          ).toFixed(2)}
        </Text>
      </Stack>
    </>
  );
}

function PaymentCard({
  billingCycle,
  invoice,
  user,
  isWarehouseManager,
}: PaymentCardProps) {
  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>Transaction ref:</Text>
        <Text size='2xs'>
          {invoice.payments[0].paymentReference}
          {invoice.payments[0].status === 'PENDING' ? (
            <ErrorIcon
              sx={{
                width: 16,
                height: 16,
                fill: getNoticeColors('warning').fill,
              }}
            />
          ) : (
            <CheckCircleIcon
              sx={{ width: 16, height: 16, fill: 'var(--colors-green9)' }}
            />
          )}
        </Text>
      </Stack>
      {isWarehouseManager(user) ? (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Customer:</Text>
          <Text size='2xs'>{billingCycle.shipperBusinessName}</Text>
        </Stack>
      ) : (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Supplier:</Text>
          <Text size='2xs'>{billingCycle.lspBusinessName}</Text>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>
          {invoice.payments[0].status === 'PENDING'
            ? 'Payment raised:'
            : 'Payment processed:'}
        </Text>
        <Text size='2xs'>
          {invoice.payments[0].status === 'PENDING'
            ? dayjs(invoice.payments[0].createdAt).format('DD/MM/YYYY')
            : dayjs(invoice.payments[0].createdAt).format('DD/MM/YYYY')}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>Transaction value:</Text>
        <Text size='2xs' weight='bold'>
          {invoice.payments[0].currencySymbol}{' '}
          {invoice.payments[0].amount.toFixed(2)}
        </Text>
      </Stack>
    </>
  );
}

interface InvoicingEventCardProps extends ComponentPropsWithoutRef<'div'> {
  billingCycle?: BillingCycle;
  invoice: Invoice;
  payment?: boolean;
  onClick: () => void;
}

export function InvoicingEventCard({
  billingCycle,
  invoice,
  payment = false,
  onClick,
}: InvoicingEventCardProps) {
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();

  const calculateOverdue = (dueDate?: string, paidAt?: string) => {
    if (!dueDate) return { isOverdue: false, daysOverdue: 0 };
    const daysOverdue = calculateDaysUntilDue(dueDate);
    const isOverdue = daysOverdue > 0 && !payment && !paidAt;
    return { isOverdue, daysOverdue: isOverdue ? daysOverdue : 0 };
  };

  const { isOverdue, daysOverdue } = calculateOverdue(
    invoice.dueDate,
    invoice.paidAt
  );

  return (
    <Stack
      direction='column'
      useFlexGap
      sx={{
        backgroundColor: 'var(--colors-gray1)',
        transition: 'background-color var(--transitions-fast)',
        p: 8,
        borderRadius: '0.5rem',
        border: isOverdue ? '1px solid red' : '1px solid transparent',
        gap: 4,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--colors-gray2)',
        },
        overflow: 'hidden',
        flexShrink: '0',
      }}
      onClick={onClick}
    >
      {billingCycle && !payment && (
        <InvoiceCard
          billingCycle={billingCycle}
          invoice={invoice}
          user={user}
          isWarehouseManager={isWarehouseManager}
          isOverdue={isOverdue}
          daysOverdue={daysOverdue}
        />
      )}
      {billingCycle && payment && (
        <PaymentCard
          billingCycle={billingCycle}
          invoice={invoice}
          user={user}
          isWarehouseManager={isWarehouseManager}
        />
      )}
    </Stack>
  );
}

InvoicingEventCard.displayName = 'InvoicingEventCard';
