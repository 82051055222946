import { BillingCycle, InvoiceLine, VAT_RATE } from './invoiceTypes';

export function getVatCost(amount: number) {
  return (amount * VAT_RATE) / 100;
}

function getInvoiceLineCost(line: InvoiceLine) {
  return line.amount;
}

export function getInvoiceLineVatCost(line: InvoiceLine) {
  return getVatCost(line.amount);
}

export function getBillingCycleCost(billingCycle: BillingCycle): number {
  return billingCycle?.floxToShipperInvoice?.lines
    ? billingCycle.floxToShipperInvoice.lines.reduce(
        (sum, line) => sum + getInvoiceLineCost(line),
        0
      )
    : 0;
}

export function getBillingCycleVatCost(billingCycle: BillingCycle): number {
  return billingCycle?.floxToShipperInvoice?.lines
    ? billingCycle.floxToShipperInvoice.lines.reduce(
        (sum, line) => sum + getInvoiceLineVatCost(line),
        0
      )
    : 0;
}

export function buildPaymentReference(billingCycles: BillingCycle[]) {
  if (billingCycles.length === 0) {
    return '';
  }
  const yapilyReferenceCharacterLimit = 18;
  // invoiceNumber format (finalised) is:
  // LSPI-{5 digit shipper code}-{5 digit lsp code}-{5 digit invoiceCount}-{BillingCycle created date as mmYY}
  if (billingCycles.length === 1) {
    const invoiceNumber = billingCycles[0].floxToShipperInvoice.invoiceNumber.replace(
      ' ',
      ''
    );
    const lspCodeStartIndex = invoiceNumber.indexOf('-', 9) + 1;
    return invoiceNumber.substring(
      lspCodeStartIndex,
      lspCodeStartIndex + yapilyReferenceCharacterLimit
    );
  }

  let reference = `MX INV ${+billingCycles.length}`;
  const invoiceCodeLength = 5;
  const invoiceNumbers = billingCycles.map((billingCycle) => {
    const invoiceCodeStartIndex =
      billingCycle.floxToShipperInvoice.invoiceNumber.indexOf('-', 15) + 1;
    const invoiceCode = billingCycle.floxToShipperInvoice.invoiceNumber.substring(
      invoiceCodeStartIndex,
      invoiceCodeStartIndex + invoiceCodeLength
    );
    return ` ${+invoiceCode}`;
  });
  const fullReference = reference + invoiceNumbers.join('');
  if (fullReference.length <= yapilyReferenceCharacterLimit) {
    return fullReference;
  }

  for (let i = 0; i < invoiceNumbers.length; i += 1) {
    reference += invoiceNumbers[i];
    if (
      invoiceNumbers.length <= i + 1 ||
      reference.length + invoiceNumbers[i + 1].length >
        yapilyReferenceCharacterLimit - 2
    ) {
      break;
    }
  }
  return `${reference.substring(0, yapilyReferenceCharacterLimit - 2)} +`;
}
