// Shared navigation

import {
  NavigationItem,
  NavigationItemList,
  NavigationItemWithSublinks,
} from '@components/Navigation/types';

const noticeboardNavigation: NavigationItem = {
  id: 'noticeboard',
  label: 'Noticeboard',
  href: '/noticeboard',
};

const financeNavigation: NavigationItemWithSublinks = {
  id: 'finance',
  label: 'Finance',
  href: '#',
  sublinks: [
    {
      id: 'invoice',
      label: 'Invoices',
      href: '/invoices',
    },
    {
      id: 'payments',
      label: 'Payments',
      href: '/payments',
    },
  ],
};

// Warehouse Client navigation

const clientOperationsNavigation: NavigationItemWithSublinks = {
  id: 'operations',
  label: 'Operations',
  href: '#',
  sublinks: [
    {
      id: 'stock',
      label: 'Stock',
      href: '/stock',
    },
    {
      id: 'shipments',
      label: 'Shipments',
      href: '/shipments',
    },
    // {
    //   id: 'intraUnitSupply',
    //   label: 'Intra-Unit Supply',
    //   href: '/intra-unit-supply',
    // },
  ],
};

const clientMasterDataNavigation: NavigationItemWithSublinks = {
  id: 'masterData',
  label: 'Master data',
  href: '#',
  sublinks: [
    {
      id: 'productSKUItemsMaster',
      label: 'Products',
      href: '/product-sku-master',
      description: 'Create, view and edit Product and SKU lists.',
    },
    {
      id: 'customerMaster',
      label: 'Customers',
      href: '/customer-master',
      description: 'View and edit your customers.',
    },
    {
      id: 'supplierMaster',
      label: 'Suppliers',
      href: '/supplier-master',
      description: 'View and edit your suppliers.',
    },
  ],
};

const clientWarehouseSearchNavigation: NavigationItemWithSublinks = {
  id: 'warehousing',
  label: 'Warehousing',
  href: '#',
  sublinks: [
    {
      id: 'warehouseShortlist',
      label: 'Warehouse Shortlist',
      href: '/warehouse-shortlist',
      description: 'View and edit your warehouse shortlist.',
    },
    {
      id: 'warehouseSearch',
      label: 'Find a Warehouse',
      href: '/warehouse-search',
      description: 'Search for warehouses.',
    },
  ],
};

// Warehouse Manager navigation

const managerOperationsNavigation: NavigationItemWithSublinks = {
  id: 'operations',
  label: 'Operations',
  href: '#',
  sublinks: [
    {
      id: 'customers',
      label: 'Customers',
      href: '/customers',
    },
    {
      id: 'stock',
      label: 'Stock',
      href: '/stock',
    },
    {
      id: 'shipments',
      label: 'Shipments',
      href: '/shipments',
    },
  ],
};

const managerFleetAvailability: NavigationItemWithSublinks = {
  id: 'availability',
  label: 'Availability',
  href: '#',
  sublinks: [
    {
      id: 'fleetAvailability',
      label: 'Fleet Capacity',
      href: '/fleet-availability',
    },
    // {
    //   id: 'storageSpace',
    //   label: 'Storage Space',
    //   href: '/storage-space',
    // },
    // {
    //   id: 'coPackingOptions',
    //   label: 'Co-Packing Options',
    //   href: '/co-packing-options',
    // },
  ],
};

const warehouseMasterNavigation: NavigationItemWithSublinks = {
  id: 'masterData',
  label: 'Master Data',
  href: '#',
  sublinks: [
    {
      id: 'warehouseMaster',
      label: 'Warehouses',
      href: '/warehouse-master',
      description: 'View and edit warehouses.',
    },
    {
      id: 'fleetMaster',
      label: 'Fleet Vehicles',
      href: '/fleet-master',
      description: 'View and edit your fleet.',
    },
    {
      id: 'priceMaster',
      label: 'Transport Tariffs',
      href: '/transport-price-master',
      description: 'View and edit your ratecards.',
    },
  ],
};

export const clientNavigation: NavigationItemList = [
  noticeboardNavigation,
  clientOperationsNavigation,
  clientMasterDataNavigation,
  financeNavigation,
  clientWarehouseSearchNavigation,
];

export const managerNavigation: NavigationItemList = [
  noticeboardNavigation,
  managerOperationsNavigation,
  managerFleetAvailability,
  warehouseMasterNavigation,
  financeNavigation,
];
