import { useEffect, useState } from 'react';
import { View } from '@views/View';
import {
  WarehouseMatchList,
  Match,
} from '@views/WarehouseDetails/WarehouseDetails';
import moment from 'moment';
import {
  BillingCycle,
  Invoice,
  InvoiceAdditionalService,
  ServiceRates,
  VAT_RATE,
} from './invoiceTypes';
import { useAuthHttp } from '../../hooks';
import { APPROVE_INVOICE, MATCH_LIST } from '../../constants';
import { InvoiceTable } from './InvoiceTable';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoicePaymentDetails } from './InvoicePaymentDetails';
import {
  getBillingCycleCost,
  getBillingCycleVatCost,
  getVatCost,
} from './invoiceHelper';

export const additionalServicesOptions = [
  {
    label: 'Case Picking and Despatch',
    value: 'casePickingAndDespatchPrice',
  },
  {
    label: 'Container Handling',
    value: 'containerHandlingPrice',
  },
  {
    label: 'Demurrage',
    value: 'demurragePricePerDay',
  },
  {
    label: 'Item Picking and Despatch',
    value: 'itemPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Picking and Despatch',
    value: 'palletPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Receipt and Put Away',
    value: 'palletReceiptAndPutAwayPrice',
  },
  {
    label: 'Storage',
    value: 'storagePricePerWeek',
  },
  {
    label: 'Pallet Returns',
    value: 'palletReturnsPrice',
  },
  {
    label: 'Case Returns',
    value: 'caseReturnsPrice',
  },
  {
    label: 'Item Returns',
    value: 'itemReturnsPrice',
  },
];

export function LSPInvoice({
  billingCycle,
  invoice,
}: {
  billingCycle: BillingCycle;
  invoice: Invoice;
}) {
  const authHttp = useAuthHttp();
  const [hasConfirmed, setHasConfirmed] = useState(!!invoice.approvedAt);
  const [invoiceLines, setInvoiceLines] = useState(invoice.lines);
  const [invoiceNotes, setInvoiceNotes] = useState(invoice.notes);
  const [hasPaid, setHasPaid] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [auxiliaryServicesPrice, setAuxiliaryServicesPrice] = useState(0);
  const [manHours, setManHours] = useState(0);
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherAmountVat, setOtherAmountVat] = useState(0);
  const [additionalServices, setAdditionalServices] = useState<
    InvoiceAdditionalService[]
  >([]);
  const [match, setMatch] = useState<Match>();
  const [dueDate, setDueDate] = useState(invoice?.dueDate);

  let subTotalAmount = getBillingCycleCost(billingCycle);
  let subTotalVatAmount = getBillingCycleVatCost(billingCycle);

  subTotalAmount += manHours * auxiliaryServicesPrice;
  subTotalVatAmount += (manHours * auxiliaryServicesPrice * VAT_RATE) / 100;

  subTotalAmount += otherAmount;
  subTotalVatAmount += otherAmountVat;

  additionalServices.forEach((service) => {
    const acceptedValue =
      match?.serviceRates[service.description as keyof ServiceRates]
        ?.acceptedValue ?? 0;
    const amount = service.amount * acceptedValue;
    const vatAmout = (service.amount * (acceptedValue * VAT_RATE)) / 100;
    subTotalAmount += parseFloat(amount.toFixed(2));
    subTotalVatAmount += parseFloat(vatAmout.toFixed(2));
  });

  const fetchWarehouseMatch = async () => {
    try {
      const { data } = await authHttp.post<WarehouseMatchList>(
        MATCH_LIST,
        {
          warehouseId: billingCycle.warehouseId,
          matchId: billingCycle.warehouseMatchId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.matches.length > 0) {
        setAuxiliaryServicesPrice(
          data.matches[0].match.serviceRates.auxiliaryServicePricePerManHour
            .acceptedValue
        );
        setMatch(data.matches[0].match);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleConfirmInvoice = async () => {
    setIsConfirming(true);
    const otherDescription = (
      document.getElementById('otherDescription') as HTMLInputElement
    ).value;

    try {
      const { data } = await authHttp.post<{ billingCycle: BillingCycle }>(
        APPROVE_INVOICE,
        {
          billingCycle: {
            billingCycleId: billingCycle.id,
            approvedAt: moment(),
            notes: (document.getElementById('comments') as HTMLInputElement)
              .value,
            auxiliaryServiceManHours:
              (document.getElementById('manHours') as HTMLInputElement)
                ?.value || null,
            additionalServices:
              otherAmount > 0 && otherDescription
                ? [
                    ...additionalServices,
                    {
                      id: crypto.randomUUID(),
                      lineNumber:
                        invoice.lines.length + additionalServices.length + 2,
                      description: otherDescription,
                      amount: otherAmount,
                    },
                  ]
                : additionalServices,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.billingCycle) {
        setHasConfirmed(true);
        setInvoiceLines(data.billingCycle.lspToFloxInvoice.lines);
        setInvoiceNotes(data.billingCycle.lspToFloxInvoice.notes);
        setDueDate(data.billingCycle.lspToFloxInvoice.dueDate);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsConfirming(false);
    }
  };

  const handleAdditionalServiceDescriptionChange = (
    value: string,
    id: string
  ) => {
    const updatedServices = additionalServices.map((service) => {
      if (service.id === id) {
        return {
          ...service,
          description: value,
        };
      }

      return service;
    });

    setAdditionalServices(updatedServices);
  };

  const handleDeleteService = (serviceId: string) => {
    const updatedServices = additionalServices.filter(
      (service) => service.id !== serviceId
    );
    setAdditionalServices(updatedServices);
  };

  useEffect(() => {
    if (otherAmount) {
      setOtherAmountVat(getVatCost(otherAmount));
    } else {
      setOtherAmountVat(0);
    }
  }, [otherAmount]);

  useEffect(() => {
    fetchWarehouseMatch();
  }, []);

  return (
    <View>
      <div id='pdf-target'>
        <InvoiceSummary
          isLsp
          hasConfirmed={hasConfirmed}
          hasPaid={hasPaid}
          match={match}
          billingCycle={billingCycle}
          invoice={invoice}
        />
        <InvoiceTable
          billingCycle={billingCycle}
          invoice={invoice}
          invoiceLines={invoiceLines}
          match={match}
          subTotalAmount={subTotalAmount}
          subTotalVatAmount={subTotalVatAmount}
          editSectionProps={
            hasConfirmed
              ? null
              : {
                  handleAdditionalServiceDescriptionChange,
                  additionalServices,
                  setAdditionalServices,
                  setOtherAmount,
                  otherAmountVat,
                  handleDeleteService,
                  manHours,
                  setManHours,
                  auxiliaryServicesPrice,
                  setAuxiliaryServicesPrice,
                }
          }
        />
        <InvoicePaymentDetails
          isLsp
          hasConfirmed={hasConfirmed}
          billingCycle={billingCycle}
          invoice={invoice}
          invoiceNotes={invoiceNotes}
          dueDate={dueDate}
          isConfirming={isConfirming}
          handleConfirmInvoice={handleConfirmInvoice}
          setHasPaid={setHasPaid}
        />
      </div>
    </View>
  );
}
