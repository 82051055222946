import { useEffect, useState } from 'react';
import { INVOICE_LIST } from '@constants';
import { UserRoleService } from '@services';
import { BillingCycle } from '@views/Invoice/invoiceTypes';
import { useAuth } from '@context';
import { useAtom } from 'jotai';
import { useAuthHttp } from './useHttp';
import {
  billingCyclesAtom,
  notificationsAtom,
  paidBillingCyclesAtom,
} from '../store/jotai';

export const useInvoices = () => {
  const authHttp = useAuthHttp();
  const { isWarehouseClient } = UserRoleService();
  const { user } = useAuth();
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(false);
  const [isPaidInvoicesLoading, setIsPaidInvoicesLoading] = useState(false);
  const [billingCycles, setBillingCycles] =
    useAtom<BillingCycle[]>(billingCyclesAtom);
  const [paidBillingCycles, setPaidBillingCycles] = useAtom<BillingCycle[]>(
    paidBillingCyclesAtom
  );
  const [isLoadingInvoicesError, setIsLoadingInvoicesError] = useState(false);
  const [isLoadingPaidInvoicesError, setIsLoadingPaidInvoicesError] =
    useState(false);
  const [hasMoreInvoiceData, setHasMoreInvoiceData] = useState<boolean | null>(
    null
  );
  const [hasMorePaidInvoiceData, setHasMorePaidInvoiceData] = useState<
    boolean | null
  >(null);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [lastBillingCycle, setLastBillingCycle] = useState<BillingCycle | null>(
    null
  );
  const [lastPaidBillingCycle, setLastPaidBillingCycle] =
    useState<BillingCycle | null>(null);

  const getInvoiceScrollParent = () =>
    document.getElementById('invoice-list') as HTMLElement;

  const getPaymentsScrollParent = () =>
    document.getElementById('payment-list') as HTMLElement;

  const fetchInvoices = async () => {
    if (isInvoicesLoading) return;
    setIsInvoicesLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          statuses: isWarehouseClient(user) ? ['Finalised', 'Paid'] : '',
          pagination: {
            lastItem: lastBillingCycle,
            itemsPerPage: 25,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setBillingCycles([...billingCycles, ...data.billingCycles]);
        setLastBillingCycle(data.billingCycles[data.billingCycles.length - 1]);
        setHasMoreInvoiceData(!(data.billingCycles.length < 25));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsLoadingInvoicesError(true);
      setHasMoreInvoiceData(false);
    } finally {
      setIsInvoicesLoading(false);
    }
  };

  const fetchPaidInvoices = async () => {
    if (isPaidInvoicesLoading) return;
    setIsPaidInvoicesLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          statuses: ['Paid'],
          pagination: {
            lastItem: lastPaidBillingCycle,
            itemsPerPage: 25,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setPaidBillingCycles([...paidBillingCycles, ...data.billingCycles]);
        setLastPaidBillingCycle(
          data.billingCycles[data.billingCycles.length - 1]
        );
        setHasMorePaidInvoiceData(!(data.billingCycles.length < 25));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsLoadingPaidInvoicesError(true);
      setHasMorePaidInvoiceData(false);
    } finally {
      setIsPaidInvoicesLoading(false);
    }
  };

  useEffect(() => {
    const notification = notifications[0];
    if (
      notification &&
      notification.notificationType === 'BillingCycle.InvoiceApproved'
    ) {
      fetchInvoices();
    }

    if (
      notification &&
      notification.notificationType === 'BillingCycle.PaymentMade'
    ) {
      fetchPaidInvoices();
    }
  }, [notifications]);

  return {
    billingCycles,
    paidBillingCycles,
    isInvoicesLoading,
    fetchInvoices,
    fetchPaidInvoices,
    isLoadingInvoicesError,
    hasMoreInvoiceData,
    getInvoiceScrollParent,
    isPaidInvoicesLoading,
    isLoadingPaidInvoicesError,
    hasMorePaidInvoiceData,
    getPaymentsScrollParent,
  };
};
