import { ChangeEvent, useEffect, useState } from 'react';
import { ToggleTheme } from '@components/ToggleTheme';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Input } from '@components/Input';
import { ErrorMessage } from '@components/ErrorMessage';
import { InfoMessage } from '@components/InfoMessage';
import { WarningMessage } from '@components/WarningMessage';
import { RadioGroup, RadioCard, RadioItem } from '@components/Radio';
import {
  PasswordStrengthBar,
  getPasswordStrength,
  getPasswordFeedback,
} from '@components/PasswordStrengthBar';
import { Formik, Field, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  COUNTRY_CODES,
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
  REGISTRATION_URL,
  WAREHOUSE_CLIENT,
  WAREHOUSE_MANAGER,
} from '@constants';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import dayjs from 'dayjs';
import { isAxiosRegisterError } from '@utils';
import { AxiosResponse, AxiosError } from 'axios';
import { useAuthHttp } from '@hooks';
import { useAuth } from '@context';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from '@mui/material';
import { RegisterValues, RegisterResponse, RegisterErrorData } from './types';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
  RegistrationForm,
  FormSwitchContainer,
} from './Registration.styles';
import { classNames } from '../../utils/classes';

export function Registration() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const navigate = useNavigate();
  const { logIn, setUser } = useAuth();
  const authHttp = useAuthHttp();
  const [searchParams] = useSearchParams();
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showRegisterError, setShowRegisterError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');

  useDocumentTitle('FLOX - Register');

  const role = searchParams.get('role') || '';
  const redirect = searchParams.get('redirect') || '';

  let redirectWithParams = '';

  const maxStorageCapacityMetersParam =
    searchParams.get('maxStorageCapacityMeters') || '';
  const maxStorageCapacityPalletsParam =
    searchParams.get('maxStorageCapacityPallets') || '';
  const ownFleetParam = searchParams.get('ownFleet') || '';
  const temperatureControlledParam =
    searchParams.get('temperatureControlled') || '';
  const warehouseParam = searchParams.get('warehouse') || '';
  const distributionParam = searchParams.get('distribution') || '';
  const availableStorageCapacityMetersParam =
    searchParams.get('availableStorageCapacityMeters') || '';
  const availableStorageCapacityPalletsParam =
    searchParams.get('availableStorageCapacityPallets') || '';
  const minServiceTermMonthsParam =
    searchParams.get('minServiceTermMonths') || '';
  const minServiceCapacityParam = searchParams.get('minServiceCapacity') || '';

  let businessProfile = {};

  const servicesNeeded = searchParams.get('servicesNeeded') || '';
  const storageSpaceOrMinPalletsNeeded =
    searchParams.get('storageSpaceOrMinPalletsNeeded') || '';
  const intendedStartDate = searchParams.get('intendedStartDate') || '';
  const minimalTerm = searchParams.get('minimalTerm') || '';
  const transportationNeeded = searchParams.get('transportationNeeded') || 0;
  const averageShipmentType = searchParams.get('averageShipmentType') || '';
  const averageShipmentSize = searchParams.get('averageShipmentSize') || 0;
  const needed = searchParams.get('needed') || [];
  const additionalCapabilities =
    searchParams.get('additionalCapabilities') || [];
  const sizeOfBusiness = searchParams.get('sizeOfBusiness') || '';
  const typeOfBusiness = searchParams.get('typeOfBusiness') || '';
  const supplyChainTeam = searchParams.get('supplyChainTeam') || '';

  if (role === 'logistics-client') {
    businessProfile = {
      businessType: typeOfBusiness,
      businessSize: sizeOfBusiness,
      numberOfPalletsInAndOutPerWeek: transportationNeeded,
      averageShipmentType,
      averageShipmentSizePerWeek: averageShipmentSize,
      teamSize: supplyChainTeam,
    };
  }

  if (role === 'logistics-provider') {
    businessProfile = {
      businessType: searchParams.get('typeOfBusiness') || '',
      businessSize: searchParams.get('sizeOfBusiness') || '',
      targetCustomerType: searchParams.get('targetCustomerType') || '',
    };
  }

  const initialValues = {
    role: searchParams.get('role') || '',
    businessName: '',
    industry: '',
    firstName: searchParams.get('firstname') || '',
    lastName: searchParams.get('lastname') || '',
    mobilePhoneNumber: '',
    email: searchParams.get('email') || '',
    password: '',
    hasAgreedToTandC: false,
    country: 'AF',
  };

  const registerValidationSchema = Yup.object({
    role: Yup.string()
      .oneOf(['logistics-client', 'logistics-provider'])
      .required('Please select what service you need.'),
    businessName: Yup.string()
      .min(3, 'Company name must have at least 3 characters.')
      .required('A business name is required.'),
    industry: Yup.string()
      .min(3, 'Industry must have at least 3 characters.')
      .required('An industry is required.'),
    firstName: Yup.string()
      .min(3, 'First name must have at least 3 characters.')
      .required('A first name is required.'),
    lastName: Yup.string()
      .min(3, 'Last name must have at least 3 characters.')
      .required('A last name is required.'),
    country: Yup.string().required('Please select a country.'),
    mobilePhoneNumber: Yup.string()
      .matches(/^\+?(?:[0-9] ?){6,14}[0-9]$/, {
        message: 'Please enter a valid mobile phone number',
      })
      .required('A mobile phone number is required.'),
    email: Yup.string()
      .email('Invalid email address.')
      .required('An email address is required.'),
    password: Yup.string()
      .min(8, 'Password must have at least 8 characters.')
      .required('A password is required.')
      .test({
        name: 'password-strength-test',
        test: (value, ctx) => {
          if (!(getPasswordStrength(value as string) > 1)) {
            return ctx.createError({
              message: getPasswordFeedback(value as string),
            });
          }
          return true;
        },
      }),
    hasAgreedToTandC: Yup.boolean().oneOf([true], 'Field must be checked'),
  });

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<RegisterValues>
  ): void => {
    setRegisterErrorMessage('');
    setShowRegisterError(false);

    if (role === 'logistics-client') {
      if (redirect.includes('?')) {
        const redirectParams = `servicesNeeded=${servicesNeeded}&storageSpaceOrMinPalletsNeeded=${storageSpaceOrMinPalletsNeeded}&intendedStartDate=${intendedStartDate}&minimalTerm=${minimalTerm}&transportationNeeded=${transportationNeeded}&averageShipmentType=${averageShipmentType}&averageShipmentSize=${averageShipmentSize}&needed=${needed}&additionalCapabilities=${additionalCapabilities}&sizeOfBusiness=${sizeOfBusiness}&typeOfBusiness=${typeOfBusiness}&supplyChainTeam=${supplyChainTeam}&role=${role}`;
        redirectWithParams = `${redirect}&${redirectParams}`;
        redirectWithParams = `${redirect}?${redirectParams}`;
      }
    }

    if (role === 'logistics-provider') {
      if (redirect.includes('?')) {
        const redirectParams = `maxStorageCapacityMeters=${maxStorageCapacityMetersParam}&maxStorageCapacityPallets=${maxStorageCapacityPalletsParam}&ownFleet=${ownFleetParam}&temperatureControlled=${temperatureControlledParam}&warehouse=${warehouseParam}&distribution=${distributionParam}&availableStorageCapacityMeters=${availableStorageCapacityMetersParam}&availableStorageCapacityPallets=${availableStorageCapacityPalletsParam}&minServiceTermMonths=${minServiceTermMonthsParam}&minServiceCapacity=${minServiceCapacityParam}&name=${values.firstName}%20${values.lastName}&email=${values.email}&phone=${values.mobilePhoneNumber}`;
        redirectWithParams = `${redirect}&${redirectParams}`;
        redirectWithParams = `${redirect}?${redirectParams}`;
      }
    }

    authHttp
      .post(REGISTRATION_URL, {
        role:
          values.role === 'logistics-client'
            ? WAREHOUSE_CLIENT
            : WAREHOUSE_MANAGER,
        businessName: values.businessName,
        firstName: values.firstName,
        lastName: values.lastName,
        mobilePhoneNumber: `+${
          COUNTRY_CODES.find(
            (country) => country.country_code === values.country
          )?.phone_code
        }${values.mobilePhoneNumber}`,
        email: values.email,
        password: values.password,
        hasAgreedToTandC: values.hasAgreedToTandC,
        isSkippingRegistrationEmail: false,
        baseUrl: null,
        businessProfile: { ...businessProfile, industry: values.industry },
      })
      .then(async ({ data }: AxiosResponse<RegisterResponse>) => {
        if (process.env.NODE_ENV === 'development') {
          setUser(data.registrationResult.user);
          logIn(
            data.registrationResult.user,
            data.auth,
            redirectWithParams || redirect || '/noticeboard'
          );
        } else {
          try {
            const response = await fetch(
              'https://api.hsforms.com/submissions/v3/integration/submit/3810753/fd613e6a-cac7-450e-941e-2c05c8c69bc0',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  submittedAt: dayjs().valueOf(),
                  fields: [
                    {
                      objectTypeId: '0-1',
                      name: 'email',
                      value: values.email,
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'firstname',
                      value: values.firstName,
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'lastname',
                      value: values.lastName,
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'phone',
                      value: `+${
                        COUNTRY_CODES.find(
                          (country) => country.country_code === values.country
                        )?.phone_code
                      }${values.mobilePhoneNumber}`,
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'company',
                      value: values.businessName,
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'client_type',
                      value:
                        values.role === 'logistics-client'
                          ? 'Buyer (Shipper)'
                          : 'Seller (LSP)',
                    },
                    {
                      objectTypeId: '0-1',
                      name: 'funnel_stage',
                      value: 'Account created',
                    },
                  ],
                }),
              }
            );

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          } catch (error) {
            console.error('There was an error!', error);
          }
          setUser(data.registrationResult.user);
          logIn(
            data.registrationResult.user,
            data.auth,
            redirectWithParams || redirect || '/noticeboard'
          );
        }
      })
      .catch((err: AxiosError<RegisterErrorData>) => {
        setSubmitting(false);

        if (isAxiosRegisterError(err)) {
          let errors = err.response?.data?.registrationResult?.errors;
          if (!errors) {
            errors =
              (err.response?.data as any | undefined)?.errors.map(
                (x: any) => x.errorMessage
              ) ?? [];
          }
          const errorMessages = errors?.map((message: string) =>
            message.replace('[', '').replace(']', '')
          ) as string[];

          if (err?.response?.status === 400) {
            setRegisterErrorMessage(errorMessages[0]);
          } else {
            setRegisterErrorMessage('Something went wrong.');
          }
        }

        setShowRegisterError(true);
      });
  };

  return (
    <Container>
      <ImagePanel variant='register' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          <Logo
            src={darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC}
            alt='Flox logo'
          />
          <Heading
            as='h1'
            size={isTablet ? 'md' : 'sm'}
            css={{ textAlign: isTablet ? 'right' : 'center' }}
          >
            Create an account
          </Heading>
        </LogoTitleContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={registerValidationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleReset,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
          }) => (
            <RegistrationForm>
              {showRegisterError && (
                <WarningMessage>{registerErrorMessage}</WarningMessage>
              )}
              <InputGroup
                css={{
                  gap: '$space0',
                  display: role ? 'none' : 'initial',
                }}
              >
                <RadioGroup
                  defaultValue={searchParams?.get('role') || undefined}
                  name='user'
                  value={values.role}
                  orientation={isTablet ? 'horizontal' : 'vertical'}
                  onValueChange={(value: string) => {
                    setFieldTouched('role');
                    setFieldValue('role', value);
                  }}
                  isInvalid={(errors.role && touched.role) as boolean}
                  disabled={isSubmitting}
                  css={{
                    mb: errors.role && touched.role && '$space8',
                    gap: '$space16',
                  }}
                >
                  <RadioCard htmlFor='logistics-client' disabled={isSubmitting}>
                    <RadioItem id='logistics-client' value='logistics-client' />
                    I need logistics services.
                  </RadioCard>
                  <RadioCard
                    htmlFor='logistics-provider'
                    disabled={isSubmitting}
                  >
                    <RadioItem
                      id='logistics-provider'
                      value='logistics-provider'
                    />
                    I am a Logistics Service Provider.
                  </RadioCard>
                </RadioGroup>
                {errors.role && touched.role && (
                  <ErrorMessage>{errors.role}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='businessName' visuallyHidden>
                  Business name
                </Label>
                <Field
                  as={Input}
                  type='text'
                  id='businessName'
                  name='businessName'
                  placeholder='Enter your business name...'
                  isInvalid={
                    (errors.businessName && touched.businessName) as boolean
                  }
                  disabled={isSubmitting}
                  css={{
                    mb:
                      errors.businessName && touched.businessName && '$space8',
                  }}
                />
                {errors.businessName && touched.businessName && (
                  <ErrorMessage>{errors.businessName}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='industry' visuallyHidden>
                  Your industry
                </Label>
                <Field
                  as={Input}
                  type='text'
                  id='industry'
                  name='industry'
                  placeholder='Enter your industry...'
                  isInvalid={(errors.industry && touched.industry) as boolean}
                  disabled={isSubmitting}
                  css={{
                    mb: errors.industry && touched.industry && '$space8',
                  }}
                />
                {errors.industry && touched.industry && (
                  <ErrorMessage>{errors.industry}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='firstName' visuallyHidden>
                  First name
                </Label>
                <Field
                  as={Input}
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder='Enter your first name...'
                  isInvalid={(errors.firstName && touched.firstName) as boolean}
                  autoComplete='given-name'
                  disabled={isSubmitting}
                  css={{
                    mb: errors.firstName && touched.firstName && '$space8',
                  }}
                />
                {errors.firstName && touched.firstName && (
                  <ErrorMessage>{errors.firstName}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='lastName' visuallyHidden>
                  Last name
                </Label>
                <Field
                  as={Input}
                  type='text'
                  id='lastName'
                  name='lastName'
                  placeholder='Enter your last name...'
                  isInvalid={(errors.lastName && touched.lastName) as boolean}
                  autoComplete='family-name'
                  disabled={isSubmitting}
                  css={{
                    mb: errors.lastName && touched.lastName && '$space8',
                  }}
                />
                {errors.lastName && touched.lastName && (
                  <ErrorMessage>{errors.lastName}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='mobilePhoneNumber' visuallyHidden>
                  Mobile phone number
                </Label>
                <div className='w-full'>
                  <div
                    className={classNames(
                      isSubmitting ? 'outline-gray-200' : '',
                      'flex rounded-lg bg-white outline outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-gray-600'
                    )}
                  >
                    <div className='grid shrink-0 grid-cols-1 focus-within:relative'>
                      <Field
                        as='select'
                        id='country'
                        name='country'
                        autoComplete='country'
                        aria-label='Country'
                        disabled={isSubmitting}
                        className='col-start-1 row-start-1 w-full appearance-none rounded-lg py-1.5 pl-3 pr-7 text-base text-gray-500 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 focus:shadow-none focus:ring-offset-0 focus:ring-0  sm:text-sm/6 border-none'
                      >
                        {COUNTRY_CODES.map((country) => (
                          <option
                            value={country.country_code}
                            key={country.country_code}
                          >
                            {country.country_code} +{country.phone_code}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <Field
                      type='tel'
                      id='mobilePhoneNumber'
                      name='mobilePhoneNumber'
                      placeholder='Enter your mobile phone number...'
                      autoComplete='tel'
                      disabled={isSubmitting}
                      className='block min-w-0 grow py-3 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 focus:shadow-none focus:ring-offset-0 focus:ring-0 sm:text-sm/6 border-none bg-transparent disabled:opacity-50'
                    />
                  </div>
                  {errors.country && touched.country && (
                    <ErrorMessage>{errors.country}</ErrorMessage>
                  )}
                  {errors.mobilePhoneNumber && touched.mobilePhoneNumber && (
                    <ErrorMessage>{errors.mobilePhoneNumber}</ErrorMessage>
                  )}
                </div>
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='email' visuallyHidden>
                  Email
                </Label>
                <Field
                  as={Input}
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Enter your email...'
                  isInvalid={(errors.email && touched.email) as boolean}
                  autoComplete='email'
                  disabled={isSubmitting}
                  css={{ mb: errors.email && touched.email && '$space8' }}
                />
                {errors.email && touched.email && (
                  <ErrorMessage>{errors.email}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='password' visuallyHidden>
                  Password
                </Label>
                <Input
                  type='password'
                  id='password'
                  name='password'
                  placeholder='Enter a password...'
                  isInvalid={(errors.password && touched.password) as boolean}
                  value={values.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('password');
                    setFieldValue('password', e.target.value);
                    setPasswordStrength(getPasswordStrength(e.target.value));
                  }}
                  onBlur={() => setPasswordFocus(false)}
                  onFocus={() => setPasswordFocus(true)}
                  autoComplete='new-password'
                  disabled={isSubmitting}
                />
                <InfoMessage
                  css={{
                    pt: '$space8',
                    mb:
                      (errors.password && touched.password) || passwordFocus
                        ? '$space8'
                        : '$space0',
                  }}
                >
                  8 characters minimum, including a number.
                </InfoMessage>
                <PasswordStrengthBar
                  score={passwordStrength}
                  css={{
                    display: passwordFocus ? 'flex' : 'none',
                    mb: '$space8',
                    px: '$space16',
                  }}
                />
                {errors.password && touched.password && (
                  <ErrorMessage>{errors.password}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup
                direction='row'
                css={{
                  gap: '$space16',
                  padding: '$space2',
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.hasAgreedToTandC}
                        onChange={(
                          event: ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ) => {
                          setFieldTouched('hasAgreedToTandC');
                          setFieldValue('hasAgreedToTandC', checked);
                        }}
                      />
                    }
                    label={
                      <Typography>
                        I agree to the{' '}
                        <Link
                          href='https://www.flox.is/platform-terms'
                          target='_blank'
                          rel='noreferrer'
                          className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                        >
                          Terms and Conditions
                        </Link>
                        .
                      </Typography>
                    }
                  />
                  {errors.hasAgreedToTandC && touched.hasAgreedToTandC && (
                    <ErrorMessage>{errors.hasAgreedToTandC}</ErrorMessage>
                  )}
                </FormGroup>
              </InputGroup>
              <Button
                type='submit'
                variant='primary'
                action='cta'
                size='lg'
                isLoading={isSubmitting}
                loadingIcon={<Icon name='loading' />}
                loadingText='Creating account...'
                isFullWidth
                disabled={!isValid || isSubmitting}
                css={{ mt: '$space16' }}
              >
                Create account
              </Button>
              <FormSwitchContainer>
                <p>Already have an account?</p>
                <Button
                  variant='link'
                  size={isMobile ? 'md' : 'sm'}
                  onClick={() => {
                    handleReset();
                    navigate('/login');
                  }}
                >
                  Log in
                </Button>
              </FormSwitchContainer>
            </RegistrationForm>
          )}
        </Formik>
      </FormPanel>
    </Container>
  );
}
