// Section to show the billing cycles and invoice lines for each to explain what the payment was for

import { Heading } from '@components/Heading';
import { InvoicePaymentCard } from '@views/Invoice/InvoicePaymentCard';
import { BillingCycle, Payment } from '@views/Invoice/invoiceTypes';

// Should be a list/table of all invoice lines grouped by billing cycle, split into line cost + vat
export function PaymentDetails({
  billingCycles,
}: {
  billingCycles: BillingCycle[];
}) {
  return (
    <div className='space-y-4'>
      <Heading as='h3' size='xs' className='mb-4'>
        This payment covers
        {billingCycles.length > 1 ? ' these invoices:' : ' this invoice:'}
      </Heading>
      {billingCycles.map((billingCycle) => (
        <InvoicePaymentCard
          isPaid
          key={billingCycle.id}
          billingCycle={billingCycle}
          invoice={billingCycle.floxToShipperInvoice}
          onToggleSelected={() => {}}
        />
      ))}
    </div>
  );
}
