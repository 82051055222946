import { View } from '@views/View';
import {
  Match,
  WarehouseMatchList,
} from '@views/WarehouseDetails/WarehouseDetails';
import { useEffect, useState } from 'react';
import { BillingCycle, Invoice } from './invoiceTypes';
import { InvoiceTable } from './InvoiceTable';
import { MATCH_LIST } from '../../constants';
import { useAuthHttp } from '../../hooks';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoicePaymentDetails } from './InvoicePaymentDetails';
import { getBillingCycleCost, getBillingCycleVatCost } from './invoiceHelper';

export function ShipperInvoice({
  billingCycle,
  invoice,
}: {
  billingCycle: BillingCycle;
  invoice: Invoice;
}) {
  const authHttp = useAuthHttp();
  const [match, setMatch] = useState<Match>();
  const [hasPaid, setHasPaid] = useState<boolean>(false); // TODO: update this to use value from BE when implemented

  const fetchWarehouseMatch = async () => {
    try {
      const { data } = await authHttp.post<WarehouseMatchList>(
        MATCH_LIST,
        {
          warehouseId: billingCycle.warehouseId,
          matchId: billingCycle.warehouseMatchId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.matches.length > 0) {
        setMatch(data.matches[0].match);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWarehouseMatch();
  }, []);

  const subTotalAmount = getBillingCycleCost(billingCycle);
  const subTotalVatAmount = getBillingCycleVatCost(billingCycle);

  return (
    <View>
      <div id='pdf-target'>
        <InvoiceSummary
          hasConfirmed
          hasPaid={hasPaid}
          match={match}
          billingCycle={billingCycle}
          invoice={invoice}
        />
        <InvoiceTable
          billingCycle={billingCycle}
          invoice={invoice}
          invoiceLines={invoice.lines}
          match={match}
          subTotalAmount={subTotalAmount}
          subTotalVatAmount={subTotalVatAmount}
          editSectionProps={null}
        />
        <InvoicePaymentDetails
          hasConfirmed
          billingCycle={billingCycle}
          invoice={invoice}
          invoiceNotes={invoice.notes}
          setHasPaid={setHasPaid}
          dueDate={invoice?.dueDate}
        />
      </div>
    </View>
  );
}
