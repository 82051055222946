/* eslint-disable no-nested-ternary */
import { Button } from '@components/Button';
import { FormProgress } from '@components/FormProgress';
import { Icon } from '@components/Icon';
import { ModalContent, ModalFooter } from '@components/Modal';
import { useEffect, useState } from 'react';
import { MdCheck, MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useSetAtom } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserImage } from '@forms/client/AddProductForm/types';
import { isWarehouseModalOpenAtom } from '../../../store/jotai';
import {
  InitialSetup,
  CapacityAndCharacteristics,
  CurrentAvailability,
  CompanyInfo,
  WorkingHours,
  Access,
  Experience,
  WarehouseServices,
  Fleet,
  Capabilities,
} from './steps';
import { Facility } from './steps/Facility';
import { useAuth } from '../../../context';

const schema = [
  Yup.object({
    name: Yup.string()
      .required('Warehouse name required')
      .min(3, 'Warehouse name has to be at least 3 characters long'),
    description: Yup.string().nullable(),
    address: Yup.string()
      .required('Warehouse address is required')
      .min(3, 'Warehouse address has to be at least 3 characters long'),
    city: Yup.string().nullable(),
    country: Yup.string().nullable(),
    location: Yup.object().shape({
      lon: Yup.number(),
      lat: Yup.number(),
    }),
  }),
  Yup.object({
    temperatureControlTypes: Yup.string().required(
      'Temperature control is required'
    ),
    temperatureMonitoringCapeabilities: Yup.array().of(Yup.string()),
    storageCapacityUnitOfMeasure: Yup.string().required(
      'Capacity UoM is required'
    ),
    maxStorageCapacity: Yup.number()
      .required('Max storage capacity is required')
      .typeError('Max storage capacity must be a number')
      .moreThan(0, 'Max storage capacity must be greater than 0'),
    maxStorageCapacityAvailable: Yup.number()
      .required('Max storage capacity available is required')
      .typeError('Max storage capacity available must be a number')
      .moreThan(0, 'Max storage capacity available must be greater than 0'),
    currentlyAvailableCapacity: Yup.number()
      .required('Available capacity is required')
      .typeError('Available capacity must be a number')
      .moreThan(0, 'Available capacity must be greater than 0'),
    minStorageCapacityThreshold: Yup.number()
      .required('Min capacity requirements is required')
      .typeError('Min capacity requirements must be a number')
      .moreThan(0, 'Min capacity requirements must be greater than 0'),
    storageTimeUnitOfMeasure: Yup.string(),
    minStorageTimeThreshold: Yup.number()
      .required('Min term is required')
      .typeError('Min term must be a number')
      .moreThan(0, 'Min term must be greater than 0'),
    loadingDocks: Yup.number()
      .required('# of Loading bays is required')
      .typeError('# of Loading bays must be a number')
      .moreThan(0, '# of Loading bays must be greater than 0'),
    officeType: Yup.string(),
    hasOnsiteOffice: Yup.boolean(),
    onsiteOfficePhoneNumber: Yup.string()
      .matches(/^\+?(?:[0-9] ?){6,14}[0-9]$/, {
        message: 'Please enter a valid phone number',
        excludeEmptyString: true,
      })
      .nullable(),
    generalDescription: Yup.string(),
  }),
  Yup.object({
    currency: Yup.string().required('Currency is required'),
    palletReceiptAndPutAwayPrice: Yup.number()
      .typeError('Receipt and putaway price must be a number')
      .nullable()
      .moreThan(-1, 'Receipt and putaway price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    palletPickingAndDespatchPrice: Yup.number()
      .typeError('Pallet picking and despatch price must be a number')
      .nullable()
      .moreThan(-1, 'Pallet picking and despatch price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    palletLayerPickingAndDespatchPrice: Yup.number()
      .typeError('Pallet layer picking and despatch price must be a number')
      .nullable()
      .moreThan(
        -1,
        'Pallet layer picking and despatch price cannot be negative'
      )
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    casePickingAndDespatchPrice: Yup.number()
      .typeError('Case picking and despatch price must be a number')
      .nullable()
      .moreThan(-1, 'Case picking and despatch price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    subsequentCasePickingAndDespatchPrice: Yup.number()
      .typeError('Subsequent case picking and despatch price must be a number')
      .nullable()
      .moreThan(
        -1,
        'Subsequent case picking and despatch price cannot be negative'
      )
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    itemPickingAndDespatchPrice: Yup.number()
      .typeError('Item picking and despatch price must be a number')
      .nullable()
      .moreThan(-1, 'Item picking and despatch price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    subsequentItemPickingAndDespatchPrice: Yup.number()
      .typeError('Subsequent item picking and despatch price must be a number')
      .nullable()
      .moreThan(
        -1,
        'Subsequent item picking and despatch price cannot be negative'
      )
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    containerHandlingPrice: Yup.number()
      .typeError('Container handling price must be a number')
      .nullable()
      .moreThan(-1, 'Container handling price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    palletStoragePricePerWeek: Yup.number()
      .typeError('Storage pallet price must be a number')
      .nullable()
      .moreThan(-1, 'Storage pallet price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    squareFeetStoragePricePerWeek: Yup.number()
      .typeError('Storage square feet price must be a number')
      .nullable()
      .moreThan(-1, 'Storage square feet price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    squareMetreStoragePricePerWeek: Yup.number()
      .typeError('Storage square metre price must be a number')
      .nullable()
      .moreThan(-1, 'Storage square metre price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    demurragePricePerDay: Yup.number()
      .typeError('Demurrage price must be a number')
      .nullable()
      .moreThan(-1, 'Demurrage price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    palletReturnsPrice: Yup.number()
      .typeError('Pallet return price must be a number')
      .nullable()
      .moreThan(-1, 'Pallet return price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    itemReturnsPrice: Yup.number()
      .typeError('Item return price must be a number')
      .nullable()
      .moreThan(-1, 'Item return price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    caseReturnsPrice: Yup.number()
      .typeError('Case return price must be a number')
      .nullable()
      .moreThan(-1, 'Case return price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    auxiliaryServicePricePerManHour: Yup.number()
      .typeError('Auxiliary services price must be a number')
      .nullable()
      .moreThan(-1, 'Auxiliary services price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    additionalSurchargesPercentage: Yup.number()
      .typeError('Surcharges price must be a number')
      .nullable()
      .moreThan(-1, 'Surcharges price cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
  }),
  Yup.object({
    standardBusinessHoursType: Yup.string().required(
      'Standard business hours type is required'
    ),
    numberOfShifts: Yup.number()
      .typeError('Number of shifts must be a number')
      .nullable()
      .moreThan(-1, 'Number of shifts cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    minHeadcount: Yup.number()
      .typeError('Min headcount must be a number')
      .nullable()
      .moreThan(-1, 'Min headcount cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    maxHeadcount: Yup.number()
      .typeError('Max headcount must be a number')
      .nullable()
      .moreThan(-1, 'Max headcount cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    mondayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Monday opening time is required')
          : $schema
    ),
    mondayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Monday closing time is required')
          : $schema
    ),
    tuesdayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Tuesday opening time is required')
          : $schema
    ),
    tuesdayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Tuesday closing time is required')
          : $schema
    ),
    wednesdayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Wednesday opening time is required')
          : $schema
    ),
    wednesdayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Wednesday closing time is required')
          : $schema
    ),
    thursdayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Thursday opening time is required')
          : $schema
    ),
    thursdayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Thursday closing time is required')
          : $schema
    ),
    fridayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Friday opening time is required')
          : $schema
    ),
    fridayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Friday closing time is required')
          : $schema
    ),
    saturdayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Saturday opening time is required')
          : $schema
    ),
    saturdayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Saturday closing time is required')
          : $schema
    ),
    sundayOpen: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Sunday opening time is required')
          : $schema
    ),
    sundayClose: Yup.string().when(
      'standardBusinessHoursType',
      ([standardBusinessHoursType], $schema) =>
        standardBusinessHoursType === 'Other'
          ? $schema.required('Sunday closing time is required')
          : $schema
    ),
    loadingMondayOpen: Yup.string().required(
      'Monday loading open time is required'
    ),
    loadingMondayClose: Yup.string().required(
      'Monday loading close time is required'
    ),
    loadingTuesdayOpen: Yup.string().required(
      'Tuesday loading open time is required'
    ),
    loadingTuesdayClose: Yup.string().required(
      'Tuesday loading close time is required'
    ),
    loadingWednesdayOpen: Yup.string().required(
      'Wednesday loading open time is required'
    ),
    loadingWednesdayClose: Yup.string().required(
      'Wednesday loading close time is required'
    ),
    loadingThursdayOpen: Yup.string().required(
      'Thursday loading open time is required'
    ),
    loadingThursdayClose: Yup.string().required(
      'Thursday loading close time is required'
    ),
    loadingFridayOpen: Yup.string().required(
      'Friday loading open time is required'
    ),
    loadingFridayClose: Yup.string().required(
      'Friday loading close time is required'
    ),
    loadingSaturdayOpen: Yup.string().required(
      'Saturday loading open time is required'
    ),
    loadingSaturdayClose: Yup.string().required(
      'Saturday loading close time is required'
    ),
    loadingSundayOpen: Yup.string().required(
      'Sunday loading open time is required'
    ),
    loadingSundayClose: Yup.string().required(
      'Sunday loading close time is required'
    ),
    despatchCutoffTime: Yup.string().required(
      'Despatch cutoff time is required'
    ),
    hasFlexibleBusinessHours: Yup.boolean(),
    isMondayClosed: Yup.boolean(),
    isTuesdayClosed: Yup.boolean(),
    isWednesdayClosed: Yup.boolean(),
    isThursdayClosed: Yup.boolean(),
    isFridayClosed: Yup.boolean(),
    isSaturdayClosed: Yup.boolean(),
    isSundayClosed: Yup.boolean(),
    hasNoOperationsOnMonday: Yup.boolean(),
    hasNoOperationsOnTuesday: Yup.boolean(),
    hasNoOperationsOnWednesday: Yup.boolean(),
    hasNoOperationsOnThursday: Yup.boolean(),
    hasNoOperationsOnFriday: Yup.boolean(),
    hasNoOperationsOnSaturday: Yup.boolean(),
    hasNoOperationsOnSunday: Yup.boolean(),
  }),
  Yup.object({
    structuralUoM: Yup.string(),
    constructionYear: Yup.number()
      .typeError('Construction year must be a number')
      .nullable()
      .moreThan(0, 'Construction year cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    minEavesHeightFeet: Yup.number()
      .typeError('Min eaves height must be a number')
      .nullable()
      .moreThan(-1, 'Min eaves height cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    grossInternalAreaSquareFeet: Yup.number()
      .typeError('Gross internal area must be a number')
      .nullable()
      .moreThan(-1, 'Gross internal area cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    mezzanineAreaSquareFeet: Yup.number()
      .typeError('Mezzanine area must be a number')
      .nullable()
      .moreThan(-1, 'Mezzanine area cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    isRackingAvailable: Yup.boolean(),
    individualRackHeight: Yup.number()
      .typeError('Individual rack height must be a number')
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .when('isRackingAvailable', {
        is: true,
        then: ($schema) =>
          $schema
            .required('Individual rack height is required')
            .moreThan(0, 'Individual rack height must be greater than 0'),
      }),
    individualRackWidth: Yup.number()
      .typeError('Individual rack width must be a number')
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .when('isRackingAvailable', {
        is: true,
        then: ($schema) =>
          $schema
            .required('Individual rack width is required')
            .moreThan(0, 'Individual rack width must be greater than 0'),
      }),
    individualRackDepth: Yup.number()
      .typeError('Individual rack depth must be a number')
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .when('isRackingAvailable', {
        is: true,
        then: ($schema) =>
          $schema
            .required('Individual rack depth is required')
            .moreThan(0, 'Individual rack depth must be greater than 0'),
      }),
    totalRackHeight: Yup.number()
      .typeError('Total rack height must be a number')
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .when('isRackingAvailable', {
        is: true,
        then: ($schema) =>
          $schema
            .required('Total rack height is required')
            .moreThan(0, 'Total rack height must be greater than 0'),
      }),
    storageCapabilities: Yup.array().of(Yup.string()),
    materialHandlingEquipment: Yup.array().of(Yup.string()),
  }),
  Yup.object({
    accessSpaceUoM: Yup.string(),
    yardSpace: Yup.number().typeError('Yard space must be a number'),
    vehicleAccessHeight: Yup.number().typeError(
      '   Vehicle access height must be a number'
    ),
    leveledLoadingDocks: Yup.number()
      .typeError('Levelled loading docks must be a number')
      .nullable()
      .moreThan(-1, 'Levelled loading docks cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    doubleHeightLoadingDocks: Yup.number()
      .typeError('Double height loading docks must be a number')
      .nullable()
      .moreThan(-1, 'Double height loading docks cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    lorryParkingSpaces: Yup.number()
      .required('Lorry packing spaces is required')
      .typeError('Lorry packing spaces must be a number')
      .moreThan(-1, 'Lorry packing spaces cannot be negative'),
    carParkingSpaces: Yup.number()
      .typeError('Car parking spaces must be a number')
      .nullable()
      .moreThan(-1, 'Car parking spaces cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
  }),
  Yup.object({
    sectorExperience: Yup.array()
      .of(Yup.string().required('Sector experience is required'))
      .min(1, 'Sector experience is required'),
    notableCustomer1: Yup.string()
      .nullable()
      .when('sectorExperience', ([sectorExperience], $schema) =>
        sectorExperience?.includes('Other')
          ? $schema.required('At least one notable customer is required')
          : $schema
      ),
    notableCustomer2: Yup.string().nullable(),
    notalbeCustomer3: Yup.string().nullable(),
    notableCustomer4: Yup.string().nullable(),
  }),
  Yup.object({
    warehouseOperations: Yup.array()
      .of(Yup.string().required('Warehouse operations is required'))
      .min(1, 'Warehouse operations is required'),
    additionalServices: Yup.array().of(Yup.string()),
  }),
  Yup.object({
    hasOwnFleet: Yup.boolean(),
    arctic: Yup.number()
      .typeError('Artic must be a number')
      .nullable()
      .moreThan(-1, 'Artic cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    'rigid<26t': Yup.number()
      .typeError('Rigid < 26t must be a number')
      .nullable()
      .moreThan(-1, 'Rigid < 26t cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    rigid12t: Yup.number()
      .typeError('Rigid 12t must be a number')
      .nullable()
      .moreThan(-1, 'Rigid 12t cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    rigid7AndHalft: Yup.number()
      .typeError('Rigid 7.5t must be a number')
      .nullable()
      .moreThan(-1, 'Rigid 7.5t cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    van3AndHalft: Yup.number()
      .typeError('3.5t Vans must be a number')
      .nullable()
      .moreThan(-1, '3.5t Vans cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    'van<3AndHalft': Yup.number()
      .typeError('Vans < 3.5t must be a number')
      .nullable()
      .moreThan(-1, 'Vans < 3.5t cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    special: Yup.number()
      .typeError('Special must be a number')
      .nullable()
      .moreThan(-1, 'Special cannot be negative')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    fulfilmentServices: Yup.array().of(Yup.string()),
  }),
  Yup.object({
    systemsAvailable: Yup.array().of(Yup.string()),
    safetyAndSecurity: Yup.array().of(Yup.string()),
    accreditationsAndMemberships: Yup.array()
      .of(Yup.string().required('Accreditations is required'))
      .min(1, 'Accreditations is required'),
  }),
];

export type WarehouseFormValues = {
  name: string;
  description: string;
  address: string;
  city: string;
  country: string;
  location: {
    lon: number;
    lat: number;
  };
  temperatureControlTypes: string;
  temperatureMonitoringCapeabilities: string[];
  storageCapacityUnitOfMeasure: string;
  maxStorageCapacity: number;
  maxStorageCapacityAvailable: number;
  loadingDocks: number;
  officeType: string;
  hasOnsiteOffice: boolean;
  onsiteOfficePhoneNumber: string;
  siteImages: UserImage[];
  deletedImagesIds: string[];
  generalDescription: string;
  currentlyAvailableCapacity: number;
  minStorageCapacityThreshold: number;
  storageTimeUnitOfMeasure: string;
  minStorageTimeThreshold: number;
  currency: string;
  palletReceiptAndPutAwayPrice: number;
  palletPickingAndDespatchPrice: number;
  palletLayerPickingAndDespatchPrice: number;
  casePickingAndDespatchPrice: number;
  subsequentCasePickingAndDespatchPrice: number;
  itemPickingAndDespatchPrice: number;
  subsequentItemPickingAndDespatchPrice: number;
  containerHandlingPrice: number;
  squareFeetStoragePricePerWeek: number;
  squareMetreStoragePricePerWeek: number;
  palletStoragePricePerWeek: number;
  demurragePricePerDay: number;
  palletReturnsPrice: number;
  itemReturnsPrice: number;
  caseReturnsPrice: number;
  auxiliaryServicePricePerManHour: number;
  additionalSurchargesPercentage: number;
  businessName: string;
  businessNumber: string;
  businessAddress: string;
  businessIndustry: string;
  businessWebsite: string;
  businessContactName: string;
  businessContactPhoneNumber: string;
  businessContactEmail: string;
  standardBusinessHoursType: string;
  numberOfShifts: number;
  minHeadcount: number;
  maxHeadcount: number;
  mondayOpen: string;
  mondayClose: string;
  tuesdayOpen: string;
  tuesdayClose: string;
  wednesdayOpen: string;
  wednesdayClose: string;
  thursdayOpen: string;
  thursdayClose: string;
  fridayOpen: string;
  fridayClose: string;
  saturdayOpen: string;
  saturdayClose: string;
  sundayOpen: string;
  sundayClose: string;
  loadingMondayOpen: string;
  loadingMondayClose: string;
  loadingTuesdayOpen: string;
  loadingTuesdayClose: string;
  loadingWednesdayOpen: string;
  loadingWednesdayClose: string;
  loadingThursdayOpen: string;
  loadingThursdayClose: string;
  loadingFridayOpen: string;
  loadingFridayClose: string;
  loadingSaturdayOpen: string;
  loadingSaturdayClose: string;
  loadingSundayOpen: string;
  loadingSundayClose: string;
  despatchCutoffTime: string;
  hasFlexibleBusinessHours: boolean;
  isMondayClosed: boolean;
  isTuesdayClosed: boolean;
  isWednesdayClosed: boolean;
  isThursdayClosed: boolean;
  isFridayClosed: boolean;
  isSaturdayClosed: boolean;
  isSundayClosed: boolean;
  hasNoOperationsOnMonday: boolean;
  hasNoOperationsOnTuesday: boolean;
  hasNoOperationsOnWednesday: boolean;
  hasNoOperationsOnThursday: boolean;
  hasNoOperationsOnFriday: boolean;
  hasNoOperationsOnSaturday: boolean;
  hasNoOperationsOnSunday: boolean;
  structuralUoM: string;
  constructionYear: number;
  minEavesHeightFeet: number;
  grossInternalAreaSquareFeet: number;
  mezzanineAreaSquareFeet: number;
  isRackingAvailable: boolean;
  individualRackHeight: number;
  individualRackWidth: number;
  individualRackDepth: number;
  totalRackHeight: number;
  storageCapabilities: string[];
  materialHandlingEquipment: string[];
  accessSpaceUoM: string;
  yardSpace: number;
  vehicleAccessHeight: number;
  leveledLoadingDocks: number;
  doubleHeightLoadingDocks: number;
  lorryParkingSpaces: number;
  carParkingSpaces: number;
  sectorExperience: string[];
  notableCustomer1: string;
  notableCustomer2: string;
  notalbeCustomer3: string;
  notableCustomer4: string;
  warehouseOperations: string[];
  additionalServices: string[];
  hasOwnFleet: boolean;
  VanLessThan1AndHalft: number;
  LgvLessThan3AndHalft: number;
  TwoAxleRigidLessThan7AndHalf5t: number;
  TwoAxleRigid18t: number;
  TwoAxleRigidLessThan26t: number;
  MultiAxleArticMoreThan26t: number;
  SpecialOrOther: number;
  fulfilmentServices: string[];
  systemsAvailable: string[];
  safetyAndSecurity: string[];
  accreditationsAndMemberships: string[];
};

export function WarehouseForm({
  prefilledValues,
  handleOnSubmit,
  isSubmitting,
}: {
  handleOnSubmit: (data: WarehouseFormValues) => unknown;
  prefilledValues?: object;
  isSubmitting?: boolean;
}) {
  const setIsWarehouseModalOpen = useSetAtom(isWarehouseModalOpenAtom);
  const isTablet = useMediaQuery('(min-width: 48em)');
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('New warehouse');
  const [previousStepName, setPreviousStepName] = useState<string | null>(null);

  const { user } = useAuth();

  const defaultValues = {
    name: '',
    description: '',
    address: '',
    city: '',
    country: '',
    location: {
      lon: 0,
      lat: 0,
    },
    temperatureControlTypes: undefined,
    temperatureMonitoringCapeabilities: [],
    storageCapacityUnitOfMeasure: 'Pallets',
    maxStorageCapacity: undefined,
    maxStorageCapacityAvailable: undefined,
    loadingDocks: undefined,
    officeType: 'offDock',
    hasOnsiteOffice: false,
    siteImages: [],
    deletedImagesIds: [],
    generalDescription: '',
    currentlyAvailableCapacity: undefined,
    minStorageCapacityThreshold: undefined,
    storageTimeUnitOfMeasure: 'Week',
    minStorageTimeThreshold: undefined,
    currency: undefined,
    palletReceiptAndPutAwayPrice: undefined,
    palletPickingAndDespatchPrice: undefined,
    palletLayerPickingAndDespatchPrice: undefined,
    casePickingAndDespatchPrice: undefined,
    subsequentCasePickingAndDespatchPrice: undefined,
    itemPickingAndDespatchPrice: undefined,
    subsequentItemPickingAndDespatchPrice: undefined,
    containerHandlingPrice: undefined,
    palletStoragePricePerWeek: undefined,
    squareFeetStoragePricePerWeek: undefined,
    squareMetreStoragePricePerWeek: undefined,
    demurragePricePerDay: undefined,
    palletReturnsPrice: undefined,
    auxiliaryServicePricePerManHour: undefined,
    additionalSurchargesPercentage: undefined,
    businessName: user?.businessName || '',
    businessNumber: '',
    businessAddress: '',
    businessIndustry: '',
    businessWebsite: '',
    businessContactName: '',
    businessContactPhoneNumber: '',
    businessContactEmail: '',
    standardBusinessHoursType: undefined,
    numberOfShifts: undefined,
    minHeadcount: undefined,
    maxHeadcount: undefined,
    mondayOpen: '06:00',
    mondayClose: '18:00',
    tuesdayOpen: '06:00',
    tuesdayClose: '18:00',
    wednesdayOpen: '06:00',
    wednesdayClose: '18:00',
    thursdayOpen: '06:00',
    thursdayClose: '18:00',
    fridayOpen: '06:00',
    fridayClose: '18:00',
    saturdayOpen: '06:00',
    saturdayClose: '18:00',
    sundayOpen: '06:00',
    sundayClose: '18:00',
    loadingMondayOpen: '06:00',
    loadingMondayClose: '12:00',
    loadingTuesdayOpen: '06:00',
    loadingTuesdayClose: '12:00',
    loadingWednesdayOpen: '06:00',
    loadingWednesdayClose: '12:00',
    loadingThursdayOpen: '06:00',
    loadingThursdayClose: '12:00',
    loadingFridayOpen: '06:00',
    loadingFridayClose: '12:00',
    loadingSaturdayOpen: '06:00',
    loadingSaturdayClose: '12:00',
    loadingSundayOpen: '06:00',
    loadingSundayClose: '12:00',
    despatchCutoffTime: '18:00',
    hasFlexibleBusinessHours: false,
    isMondayClosed: false,
    isTuesdayClosed: false,
    isWednesdayClosed: false,
    isThursdayClosed: false,
    isFridayClosed: false,
    isSaturdayClosed: false,
    isSundayClosed: false,
    hasNoOperationsOnMonday: false,
    hasNoOperationsOnTuesday: false,
    hasNoOperationsOnWednesday: false,
    hasNoOperationsOnThursday: false,
    hasNoOperationsOnFriday: false,
    hasNoOperationsOnSaturday: false,
    hasNoOperationsOnSunday: false,
    structuralUoM: undefined,
    constructionYear: undefined,
    minEavesHeightFeet: undefined,
    grossInternalAreaSquareFeet: undefined,
    mezzanineAreaSquareFeet: undefined,
    isRackingAvailable: false,
    individualRackHeight: undefined,
    individualRackWidth: undefined,
    individualRackDepth: undefined,
    totalRackHeight: undefined,
    storageCapabilities: [],
    materialHandlingEquipment: [],
    accessSpaceUoM: undefined,
    yardSpace: undefined,
    vehicleAccessHeight: undefined,
    leveledLoadingDocks: undefined,
    doubleHeightLoadingDocks: undefined,
    lorryParkingSpaces: undefined,
    carParkingSpaces: undefined,
    sectorExperience: [],
    notableCustomer1: '',
    notableCustomer2: '',
    notalbeCustomer3: '',
    notableCustomer4: '',
    warehouseOperations: [],
    additionalServices: [],
    hasOwnFleet: false,
    VanLessThan1AndHalft: undefined,
    LgvLessThan3AndHalft: undefined,
    TwoAxleRigidLessThan7AndHalf5t: undefined,
    TwoAxleRigid18t: undefined,
    TwoAxleRigidLessThan26t: undefined,
    MultiAxleArticMoreThan26t: undefined,
    SpecialOrOther: undefined,
    fulfilmentServices: [],
    systemsAvailable: [],
    safetyAndSecurity: [],
    accreditationsAndMemberships: [],
  };

  const methods = useForm<WarehouseFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema[currentStep]),
    mode: 'onChange',
    defaultValues: { ...defaultValues, ...prefilledValues },
  });
  const { handleSubmit, trigger, formState, getValues } = methods;

  const { errors } = formState;

  const steps = [
    <InitialSetup key={0} />,
    <CapacityAndCharacteristics key={1} />,
    <CurrentAvailability key={2} />,
    <WorkingHours key={3} />,
    <Facility key={4} />,
    <Access key={5} />,
    <Experience key={6} />,
    <WarehouseServices key={7} />,
    <Fleet key={8} />,
    <Capabilities key={9} />,
  ];

  const stepNames: { [key: number]: string } = {
    0: 'New warehouse',
    1: 'General info',
    2: 'General info',
    3: 'Warehouse spec',
    4: 'Warehouse spec',
    5: 'Warehouse spec',
    6: 'Operational info',
    7: 'Services',
    8: 'Services',
    9: 'Capabilities',
  };

  const warehouseModal = document.getElementById('warehouse-form-modal');

  const handleNextStep = async () => {
    console.log(getValues(), errors);
    const isStepValid = await trigger();
    if (isStepValid) {
      setCurrentStep((prev) => prev + 1);
      warehouseModal?.scrollTo(0, 0);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => prev - 1);
    warehouseModal?.scrollTo(0, 0);
  };

  const onClose = () => {
    setIsWarehouseModalOpen(false);
  };

  const onSubmit = async (data: WarehouseFormValues) => {
    try {
      handleOnSubmit(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
    }
  };

  useEffect(() => {
    setCurrentStepName(stepNames[currentStep]);
    setPreviousStepName(stepNames[currentStep - 1]);
  }, [currentStep]);

  return (
    <>
      <FormProgress
        totalSteps={11}
        currentStep={currentStep + 1}
        currentStepName={currentStepName}
        {...(currentStep > 0 &&
          previousStepName !== null && {
            previousStepName,
          })}
        nextStepName={stepNames[currentStep + 1]}
      />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: '100%' }}
        >
          <ModalContent
            css={{
              maxHeight: 'calc(85vh - 12.5rem)',
              minHeight: 'calc(85vh - 12.5rem)',
            }}
            id='warehouse-form-modal'
          >
            {steps[currentStep]}
          </ModalContent>

          <ModalFooter>
            <Button
              variant='secondary'
              size={isTablet ? 'md' : 'sm'}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              leftIcon={<MdChevronLeft size='1.5rem' />}
              size={isTablet ? 'md' : 'sm'}
              onClick={handlePrevStep}
              disabled={currentStep === 0}
            >
              Prev
            </Button>
            {currentStep < Object.keys(stepNames).length - 1 && (
              <Button
                type='button'
                leftIcon={
                  <MdChevronRight size={isTablet ? '1.25rem' : '1.5rem'} />
                }
                size={isTablet ? 'md' : 'sm'}
                onClick={handleNextStep}
              >
                Next
              </Button>
            )}
            {currentStep === Object.keys(stepNames).length - 1 && (
              <Button
                type='submit'
                rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
                isLoading={isSubmitting}
                loadingIcon={<Icon name='loading' />}
                loadingText='Submitting...'
                size={isTablet ? 'md' : 'sm'}
                action='cta'
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </form>
      </FormProvider>
    </>
  );
}
