/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import {
  Box,
  Button,
  CircularProgress,
  MobileStepper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Heading } from '@components/Heading';
import { View } from '@views/View';
import Map from '@views/WarehouseSearch/Map';
import { useMediaQuery } from 'usehooks-ts';
import CloseIcon from '@mui/icons-material/Close';
import { WarehouseForm } from '@forms/manager/AddWarehouseForm';
import DoneIcon from '@mui/icons-material/Done';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@components/Modal';
import { AxiosResponse } from 'axios';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { WarehouseFormValues } from '@forms/manager/AddWarehouseForm/WarehouseForm';
import { useAtom, useAtomValue } from 'jotai';
import { BusinessProfile } from '@views/Profile/Profile';
import dayjs from 'dayjs';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import {
  ACCEPT_WAREHOUSE_MATCH,
  ADD_WAREHOUSE_MATCH,
  BUSINESS_PROFILE_GET,
  DELETE_WAREHOUSE_MATCH,
  HERE_APIKEY,
  MATCH_LIST,
  PROPOSE_WAREHOUSE_MATCH_DETAILS,
  SUPPORTED_CURRENCY_GROUPS,
  WAREHOUSE_DELIST,
  WAREHOUSE_GET,
  WAREHOUSE_IMAGES_URL,
  WAREHOUSE_SEARCH_GET,
  WAREHOUSE_UPDATE,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { UserRoleService } from '../../services';
import { useAuth } from '../../context';
import {
  businessProfileAtom,
  isWarehouseModalOpenAtom,
  matchAtom,
} from '../../store/jotai';
import ukwaLogo from '../../../public/images/UKWA_Member_Logo.png';
import {
  ClientServiceRatesSideDrawer,
  ProposalFormValues,
} from './ClientServiceRatesSideDrawer';
import { ManagerServiceRatesSideDrawer } from './ManagerServiceRatesSideDrawer';

export type WarehouseMatch = {
  match: Match;
  warehouse: Warehouse;
};

export type WarehouseMatchList = {
  matches: WarehouseMatch[];
};

type WarehouseData = {
  warehouse: Warehouse;
};

export type Warehouse = {
  warehouseId: string;
  createdAt: string;
  userBusinessName: string;
  userId: string;
  userBusinessId: string;
  name: string;
  description: string;
  address: string;
  city: string;
  location: {
    lat: number;
    lon: number;
  };
  temperatureControlTypes: string[];
  temperatureMonitoringCapeabilities: string[];
  loadingDocks: number;
  isOffDock: boolean;
  isQuay: boolean;
  hasOnsiteOffice: boolean;
  onsiteOfficePhoneNumber: string;
  images: [
    {
      id: string;
      title: string;
      originalFileName: string;
      remoteName: string;
      imageLocation: string;
    }
  ];
  maxStorageCapacity: number;
  maxStorageCapacityAvailable: number;
  currentlyAvailableCapacity: number;
  minStorageCapacityThreshold: number;
  minStorageTimeThreshold: number;
  storageTimeUnitOfMeasure: string;
  storageCapacityUnitOfMeasure: string;
  currency: string;
  movementPrice: number;
  avgPricePerManHour: number;
  businessName: string;
  businessNumber: string;
  businessAddress: string;
  businessIndustry: string;
  businessWebsite: string;
  businessContactName: string;
  businessContactEmail: string;
  businessContactPhoneNumber: string;
  numberOfShifts: number;
  minHeadcount: number;
  maxHeadcount: number;
  standardBusinessHoursType: string;
  hasFlexibleBusinessHours: boolean;
  customBusinessHours: {
    monday_open: string;
    monday_close: string;
    tuesday_open: string;
    tuesday_close: string;
    wednesday_open: string;
    wednesday_close: string;
    thursday_open: string;
    thursday_close: string;
    friday_open: string;
    friday_close: string;
    saturday_open: string;
    saturday_close: string;
    sunday_open: string;
    sunday_close: string;
  };
  eavesHeightUnitOfMeasure: string;
  minEavesHeightFeet: number;
  minEavesHeightMetres: number;
  constructionYear: number;
  grossInternalAreaUnitOfMeasure: string;
  grossInternalAreaSquareFeet: number;
  grossInternalAreaSquareMetres: number;
  mezzanineAreaUnitOfMeasure: string;
  mezzanineAreaSquareFeet: number;
  mezzanineAreaSquareMetres: number;
  isRackingAvailable: boolean;
  individualRackDepth: number;
  individualRackHeight: number;
  individualRackWidth: number;
  totalRackHeight: number;
  storageCapabilities: string[];
  materialHandlingEquipment: string[];
  yardSpaceUnitOfMeasure: string;
  yardSpaceSquareMetres: number;
  yardSpaceSquareFeet: number;
  vehicleAccessHeightUnitOfMeasure: string;
  vehicleAccessHeightFeet: number;
  vehicleAccessHeightMetres: number;
  leveledLoadingDocks: number;
  doubleHeightLoadingDocks: number;
  carParkingSpaces: number;
  lorryParkingSpaces: number;
  sectorExperience: string[];
  noteableCustomers: string[];
  warehouseOperations: string[];
  fulfilmentServices: string[];
  fleet: {
    VanLessThan1AndHalft: number;
    LgvLessThan3AndHalft: number;
    TwoAxleRigidLessThan7AndHalf5t: number;
    TwoAxleRigid18t: number;
    TwoAxleRigidLessThan26t: number;
    ThreeAxleRigidLessThan26t: number;
    MultiAxleArticMoreThan26t: number;
    SpecialOrOther: number;
  };
  additionalServices: string[];
  safetyAndSecurity: string[];
  systemsAvailable: string[];
  accreditationsAndMemberships: string[];
  vehicleLoadingTimeWindows: {
    monday_open: string;
    monday_close: string;
    tuesday_open: string;
    tuesday_close: string;
    wednesday_open: string;
    wednesday_close: string;
    thursday_open: string;
    thursday_close: string;
    friday_open: string;
    friday_close: string;
    saturday_open: string;
    saturday_close: string;
    sunday_open: string;
    sunday_close: string;
  };
  dispatchCutoffTime: string;
  modifiedAt: string;
  palletStoragePricePerWeek: number;
  squareFeetStoragePricePerWeek: number;
  squareMetreStoragePricePerWeek: number;
  palletReceiptAndPutAwayPrice: number;
  palletPickingAndDespatchPrice: number;
  palletLayerPickingAndDespatchPrice: number;
  casePickingAndDespatchPrice: number;
  subsequentCasePickingAndDespatchPrice: number;
  itemPickingAndDespatchPrice: number;
  subsequentItemPickingAndDespatchPrice: number;
  containerHandlingPrice: number;
  demurragePricePerDay: number;
  palletReturnsPrice: number;
  itemReturnsPrice: number;
  caseReturnsPrice: number;
  auxiliaryServicePricePerManHour: number;
  additionalSurchargesPercentage: number;
  isDelisted: boolean;
  isFull: boolean;
  isLspBusinessDeactivated: boolean;
  authenticity: {
    authenticityLevel: string;
  };
};

const STORAGE_CAPABILITIES = [
  'Bulk Powder',
  'Bulk Liquid',
  'Hazardous Chemicals',
  'Hazardous Other',
  'High Value',
  'LME',
  'Wet Bonded',
  'Dry Bonded',
  'Container',
  'Outdoor',
  'RF Technology',
];

const MATERIAL_HANDLING_EQUIPMENT = [
  'Counter Balance',
  'Crane',
  'Reach Truck',
  'Trailer Parking',
];

const SAFETY_AND_SECURITY = [
  '24/7 Security',
  '24/7 Access',
  'Bunded',
  'Caged',
  'Sprinklered',
  'Alarmed',
  'Patrolled',
  'Fenced Yard',
  'CCTV',
  'ESFR Sprinkler System',
];

const WAREHOUSE_OPERATIONS = [
  'Receipt and Putaway',
  'Docking',
  'Picking, Packing & Despatch',
  'Domestic Fulfilment',
  'International Fulfilment',
  'Nationwide and International Distribution & Haulage',
  'Import/Export Documentation',
  'Container Devanning and Sub-sorting',
  'Shrink Wrapping',
  'Waste Disposal',
  'Flexible Operations Schedule',
];

const FULFILMENT_SERVICES = [
  '3rd Party Couriers',
  'Trans Loading',
  'Cross Docking',
  'Merge In Transit',
  'Returns Logistics',
  'Critical Inventory Logistics',
];

const ADDITIONAL_SERVICES = [
  'Re-packaging, Customised Labelling, Light Assembly & Documentation',
  'Re-processing and Quality Control Programs',
  'Return processing and Management',
  'Contract Packing',
  'Procurement and Management of All Packaging Materials',
  'Product Kitting and Repackaging',
  'Contract Manufacturing',
  'Hazardous Goods handling',
  'Special Projects',
];

type Fleet =
  | 'VanLessThan1AndHalft'
  | 'LgvLessThan3AndHalft'
  | 'TwoAxleRigidLessThan7AndHalf5t'
  | 'TwoAxleRigid18t'
  | 'TwoAxleRigidLessThan26t'
  | 'MultiAxleArticMoreThan26t'
  | 'SpecialOrOther';

export type Match = {
  id: string;
  warehouseId: string;
  shipperId: string;
  desiredStartDate: string;
  averageStorageQuantityPerWeek: number;
  estimatedDurationInMonths: number;
  averageNumberOfInboundShipmentsPerWeek: number;
  averageNumberOfOutboundShipmentsPerWeek: number;
  shipperIndustry: string;
  requestedServices: string[];
  matchStatus: string;
  billingCycleFrequency: string;
  paymentTermsInDays: number;
  originalRates: {
    storagePricePerWeek: number;
    palletReceiptAndPutAwayPrice: number;
    palletPickingAndDespatchPrice: number;
    palletLayerPickingAndDespatchPrice: number;
    casePickingAndDespatchPrice: number;
    subsequentCasePickingAndDespatchPrice: number;
    itemPickingAndDespatchPrice: number;
    subsequentItemPickingAndDespatchPrice: number;
    containerHandlingPrice: number;
    demurragePricePerDay: number;
    palletReturnsPrice: number;
    itemReturnsPrice: number;
    caseReturnsPrice: number;
    auxiliaryServicePricePerManHour: number;
    additionalSurchargesPercentage: number;
  };
  serviceRates: {
    storagePricePerWeek: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    palletReceiptAndPutAwayPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    palletPickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    palletLayerPickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    casePickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    subsequentCasePickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    itemPickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    subsequentItemPickingAndDespatchPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    containerHandlingPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    demurragePricePerDay: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    palletReturnsPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    itemReturnsPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    caseReturnsPrice: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    auxiliaryServicePricePerManHour: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
    additionalSurchargesPercentage: {
      originalValue: number;
      shipperProposedValue: number;
      lspProposedValue: number;
      acceptedValue: number;
    };
  };
  volumeDiscounts: {
    id: string;
    acceptedValue: number;
    lspProposedDiscountPercentage: number;
    minTotalMonthlyTurnover: number;
    maxTotalMonthlyTurnover: number;
    shipperProposedDiscountPercentage: number;
  }[];
  isActionRequiredByLsp: boolean;
  isActionRequiredByShipper: boolean;
  comments: {
    commentText: string;
    createdAt: string;
    createdByUserId: string;
    createdByUserName: string;
  }[];
  userBusinessName: string;
  legalDocument: {
    documentId: string;
    createdAt: string;
    updatedAt: string;
    slug: string;
    documentName: string;
    name: string;
    completedAt: string;
    status: string;
    url: string;
  };
};

interface PriceDisplayProps {
  label: string;
  price: number | string | null | undefined;
  currencySymbol: string;
}

function PriceDisplay({ label, price, currencySymbol }: PriceDisplayProps) {
  return (
    <Typography variant='body1'>
      {label}: {price ? currencySymbol : ''}
      {price ? `${price}` : '-'}
    </Typography>
  );
}

export function WarehouseDetails() {
  const isMobile = useMediaQuery('(max-width: 23.4375em)');
  const isTablet = useMediaQuery('(max-width: 48em)');
  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const navigate = useNavigate();
  const [isFetchingWarehouse, setIsFetchingWarehouse] = useState(false);
  const [warehouse, setWarehouse] = useState<Warehouse>();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { isWarehouseManager, isWarehouseClient } = UserRoleService();
  const [isUpdateWarehouseModalOpen, setIsUpdateWarehouseModalOpen] = useAtom(
    isWarehouseModalOpenAtom
  );
  const [isDelistWarehouseModalOpen, setIsDelistWarehouseModalOpen] =
    useState(false);
  const [isDelistWarehouseErrorShowing, setIsDelistWarehouseErrorShowing] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isAddingToShortlist, setIsAddingToShortlist] = useState(false);
  const [isProposing, setIsProposing] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isOnShortlist, setIsOnShortlist] = useState(false);
  const [isNoBusinessProfileOpen, setIsNoBusinessProfileOpen] = useState(false);
  const [match, setMatch] = useAtom(matchAtom);
  const [comment, setComment] = useState<{
    commentText: string;
    createdAt: string;
    createdByUserId: string;
    createdByUserName: string;
  }>();
  const [isRatesConfirmModalOpen, setIsRatesConfirmModalOpen] = useState(false);
  const [businessProfile, setBusinessProfile] = useAtom(businessProfileAtom);

  const [
    isClientServiceRatesSideDrawerOpen,
    setIsClientServiceRatesSideDrawerOpen,
  ] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const isContacting = searchParams.get('contact') || '';
  const servicesNeeded = searchParams.get('servicesNeeded') || '';
  const storageSpaceOrMinPalletsNeeded =
    searchParams.get('storageSpaceOrMinPalletsNeeded') || '';
  const intendedStartDate = searchParams.get('intendedStartDate') || '';
  const minimalTerm = searchParams.get('minimalTerm') || '';
  const transportationNeeded = searchParams.get('transportationNeeded') || '';
  const averageShipmentType = searchParams.get('averageShipmentType') || '';
  const averageShipmentSize = searchParams.get('averageShipmentSize') || '';
  const needed = searchParams.get('needed') || [];
  const additionalCapabilities =
    searchParams.get('additionalCapabilities') || [];
  const sizeOfBusiness = searchParams.get('sizeOfBusiness') || '';
  const typeOfBusiness = searchParams.get('typeOfBusiness') || '';
  const supplyChainTeam = searchParams.get('supplyChainTeam') || '';
  const email = searchParams.get('email') || '';
  const firstname = searchParams.get('firstname') || '';
  const lastname = searchParams.get('lastname') || '';

  const matchId = searchParams.get('matchId') || '';

  const [
    isManagerServiceRatesSideDrawerOpen,
    setIsManagerServiceRatesSideDrawerOpen,
  ] = useState(
    (!!matchId &&
      match?.matchStatus !== 'Reviewing T&Cs' &&
      match?.matchStatus !== 'Contracted') ||
      false
  );

  const fetchWarehouse = async () => {
    setIsFetchingWarehouse(true);
    try {
      const { data }: AxiosResponse<WarehouseData> = await authHttp.get(
        isWarehouseManager(user)
          ? WAREHOUSE_GET
          : `${WAREHOUSE_SEARCH_GET}/${id}`,
        {
          params: {
            warehouseId: id,
          },
        }
      );
      setWarehouse(data.warehouse);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetchingWarehouse(false);
    }
  };

  const fetchWarehouseShortlist = async () => {
    try {
      const { data } = await authHttp.post<WarehouseMatchList>(
        MATCH_LIST,
        {
          warehouseId: warehouse?.warehouseId,
          matchId: matchId || null,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.matches.length > 0) {
        const negotiationMatch = data.matches.find(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (match) => match.match.matchStatus !== 'Rejected'
        );

        if (negotiationMatch) {
          setMatch(negotiationMatch.match);
          setComment(negotiationMatch.match.comments?.pop());
          if (
            negotiationMatch.match.matchStatus === 'Not engaged' ||
            negotiationMatch.match.matchStatus === 'Negotiating'
          ) {
            setIsOnShortlist(true);
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchBusinessProfile = async () => {
    if (!user) return;
    setIsFetchingWarehouse(true);
    try {
      const { data }: AxiosResponse<BusinessProfile> = await authHttp.get(
        BUSINESS_PROFILE_GET
      );

      if (data) {
        setBusinessProfile(data.businessProfile);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetchingWarehouse(false);
    }
  };

  const delistWarehouse = async () => {
    setIsDelistWarehouseErrorShowing(false);
    try {
      const { data } = await authHttp.post(
        WAREHOUSE_DELIST,
        {
          warehouseId: warehouse?.warehouseId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setWarehouse(data.warehouse);
        setIsDelistWarehouseModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      setIsDelistWarehouseErrorShowing(true);
    }
  };

  useEffect(() => {
    (async () => fetchWarehouse())();
    (async () => fetchBusinessProfile())();
  }, []);

  useEffect(() => {
    if (!user) return;
    if (warehouse) fetchWarehouseShortlist();
  }, [warehouse, user]);

  const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
    (currencyGroup) => currencyGroup.currencies
  );

  let currencySymbol: string | undefined;

  if (warehouse?.currency) {
    currencySymbol = currencies.find(
      (currency) => currency.code === warehouse.currency
    )?.symbol;
  }

  const emailBody = `
  A connection request received at ${moment().format('DD/MM/YYYY')} :\n
  - from ${user?.firstName} ${user?.lastName} ${user?.email}\n
  - for ${warehouse?.name} \n
  - Looking for: ${servicesNeeded}\n
  - Storage space or min pallets needed: ${storageSpaceOrMinPalletsNeeded}\n
  - Intended start date: ${intendedStartDate}\n
  - Minimal term: ${minimalTerm}\n
  - Number of inbound shipments a week: ${transportationNeeded}\n
  - Average shipment type: ${averageShipmentType}\n
  - Average shipment size: ${averageShipmentSize}\n
  - Services Needed: ${needed}\n
  - Additional capabilities: ${additionalCapabilities}\n
  - Size of business: ${sizeOfBusiness}\n
  - Type of business: ${typeOfBusiness}\n
  - Supply chain team: ${supplyChainTeam}\n
  Please request info from the warehouse and make the intro/connection
`;

  const shipperButtonHref = user
    ? `mailto:support@flox.is?subject=FLOX System message: Shipper Connection Request&body=${encodeURIComponent(
        emailBody
      )}`
    : `/register?redirect=/warehouse/${warehouse?.warehouseId}?contact=true&role=logistics-client&email=${email}&firstname=${firstname}&lastname=${lastname}`;

  useEffect(() => {
    if (!isContacting || !user || !warehouse) return;
    window.location.href = `mailto:support@flox.is?subject=FLOX System message: Shipper Connection Request&body=${encodeURIComponent(
      emailBody
    )}`;
  }, [isContacting, user, warehouse]);

  useEffect(() => {
    if (
      match?.matchStatus === 'Reviewing T&Cs' ||
      match?.matchStatus === 'Contracted'
    ) {
      setIsOnShortlist(false);
    }
  }, [match]);

  if (isFetchingWarehouse) {
    return (
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        mt={48}
      >
        <CircularProgress size={48} sx={{ fill: 'var(--colors-gray12)' }} />
      </Stack>
    );
  }

  if (!warehouse) {
    return (
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        mt={48}
      >
        <Typography>No warehouse found</Typography>
      </Stack>
    );
  }

  const fleetMapping = {
    VanLessThan1AndHalft: 'Van < 1.5t',
    LgvLessThan3AndHalft: 'LDVs < 3.5t',
    TwoAxleRigidLessThan7AndHalf5t: 'Rigids < 7.5t',
    TwoAxleRigid18t: 'Rigids 18t',
    TwoAxleRigidLessThan26t: 'Rigids < 26t',
    MultiAxleArticMoreThan26t: 'Artics > 26t',
    SpecialOrOther: 'Special/Other',
  };

  const images = warehouse.images.map((image) => ({
    imgPath: `${WAREHOUSE_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`,
  }));

  const maxSteps = images.length;

  const warehouseImagePlaceholder = `/images/warehouse_details_placeholder.png`;

  const hasUkwaAccreditation =
    warehouse.accreditationsAndMemberships.includes('UKWA');

  const handleUpdateWarehouse = () => {
    setIsUpdateWarehouseModalOpen(true);
  };

  const handleDelistWarehouse = () => {
    setIsDelistWarehouseErrorShowing(false);
    setIsDelistWarehouseModalOpen(true);
  };

  const transformBusinessHours = (hours: {
    [key: string]: string | boolean;
  }) => {
    const newHoursObj: { [key: string]: string | boolean } = hours;
    [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].map((day) => {
      const openKey = `${day}_open`;
      const closeKey = `${day}_close`;

      const newOpenKey = `${day}Open`;
      const newCloseKey = `${day}Close`;

      newHoursObj[newOpenKey] = hours[openKey as keyof typeof hours];
      newHoursObj[newCloseKey] = hours[closeKey as keyof typeof hours];

      if (
        newHoursObj[newOpenKey] === '00:00:00' &&
        newHoursObj[newCloseKey] === '00:00:00'
      ) {
        newHoursObj[`is${day.charAt(0).toUpperCase() + day.slice(1)}Closed`] =
          true;
      }

      return null;
    });

    return newHoursObj;
  };

  const transformLoadingHours = (hours: {
    [key: string]: string | boolean;
  }) => {
    const newHoursObj: { [key: string]: string | boolean } = hours;
    [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].map((day) => {
      const openKey = `${day}_open`;
      const closeKey = `${day}_close`;

      const newOpenKey = `loading${
        day.charAt(0).toUpperCase() + day.slice(1)
      }Open`;
      const newCloseKey = `loading${
        day.charAt(0).toUpperCase() + day.slice(1)
      }Close`;

      newHoursObj[newOpenKey] = hours[openKey as keyof typeof hours];
      newHoursObj[newCloseKey] = hours[closeKey as keyof typeof hours];

      if (
        newHoursObj[newOpenKey] === '00:00:00' &&
        newHoursObj[newCloseKey] === '00:00:00'
      ) {
        newHoursObj[
          `hasNoOperationsOn${day.charAt(0).toUpperCase() + day.slice(1)}`
        ] = true;
      }

      return null;
    });

    return newHoursObj;
  };

  const workingHours = transformBusinessHours(warehouse.customBusinessHours);

  const loadingHours = transformLoadingHours(
    warehouse.vehicleLoadingTimeWindows
  );

  const handleOnSubmit = async (data: WarehouseFormValues) => {
    const { data: warehouseData } = await authHttpFormData.post(
      WAREHOUSE_UPDATE,
      {
        warehouse: {
          warehouseId: warehouse.warehouseId,
          name: data.name,
          description: data.description,
          address: data.address,
          city: data.city,
          country: data.country,
          location: data.location,
          temperatureControlTypes: data.temperatureControlTypes,
          temperatureMonitoringCapeabilities:
            data.temperatureMonitoringCapeabilities,
          loadingDocks: data.loadingDocks,
          isOffDock: data.officeType === 'offDock',
          isQuay: data.officeType === 'quay',
          hasOnsiteOffice: data.hasOnsiteOffice,
          onsiteOfficePhoneNumber: data.onsiteOfficePhoneNumber,
          maxStorageCapacity: data.maxStorageCapacity,
          maxStorageCapacityAvailable: data.maxStorageCapacityAvailable,
          currentlyAvailableCapacity: data.currentlyAvailableCapacity,
          minStorageCapacityThreshold: data.minStorageCapacityThreshold,
          minStorageTimeThreshold: data.minStorageTimeThreshold,
          storageTimeUnitOfMeasure: data.storageTimeUnitOfMeasure,
          storageCapacityUnitOfMeasure: data.storageCapacityUnitOfMeasure,
          currency: data.currency,
          palletReceiptAndPutawayPrice: data.palletReceiptAndPutAwayPrice,
          palletPickingAndDespatchPrice: data.palletPickingAndDespatchPrice,
          palletLayerPickingAndDespatchPrice:
            data.palletLayerPickingAndDespatchPrice,
          casePickingAndDespatchPrice: data.casePickingAndDespatchPrice,
          subsequentCasePickingAndDespatchPrice:
            data.subsequentCasePickingAndDespatchPrice,
          itemPickingAndDespatchPrice: data.itemPickingAndDespatchPrice,
          subsequentItemPickingAndDespatchPrice:
            data.subsequentItemPickingAndDespatchPrice,
          containerHandlingPrice: data.containerHandlingPrice,
          palletStoragePricePerWeek: data.palletStoragePricePerWeek,
          squareFeetStoragePricePerWeek: data.squareFeetStoragePricePerWeek,
          squareMetreStoragePricePerWeek: data.squareMetreStoragePricePerWeek,
          demurragePricePerDay: data.demurragePricePerDay,
          palletReturnsPrice: data.palletReturnsPrice,
          itemReturnsPrice: data.itemReturnsPrice,
          caseReturnsPrice: data.caseReturnsPrice,
          auxiliaryServicePricePerManHour: data.auxiliaryServicePricePerManHour,
          additionalSurchargesPercentage: data.additionalSurchargesPercentage,
          businessName: data.businessName,
          businessNumber: data.businessNumber,
          businessAddress: data.businessAddress,
          businessIndustry: data.businessIndustry,
          businessWebsite: data.businessWebsite,
          businessContactEmail: data.businessContactEmail,
          businessContactPhoneNumber: data.businessContactPhoneNumber,
          businessContactName: data.businessContactName,
          numberOfShifts: data.numberOfShifts,
          minHeadcount: data.minHeadcount,
          maxHeadcount: data.maxHeadcount,
          standardBusinessHoursType: data.standardBusinessHoursType,
          hasFlexibleBusinessHours: data.hasFlexibleBusinessHours,
          customBusinessHours:
            data.standardBusinessHoursType === 'Other'
              ? {
                  monday_open: data.isMondayClosed ? 'closed' : data.mondayOpen,
                  monday_close: data.isMondayClosed
                    ? 'closed'
                    : data.mondayClose,
                  tuesday_open: data.isTuesdayClosed
                    ? 'closed'
                    : data.tuesdayOpen,
                  tuesday_close: data.isTuesdayClosed
                    ? 'closed'
                    : data.tuesdayClose,
                  wednesday_open: data.isWednesdayClosed
                    ? 'closed'
                    : data.wednesdayOpen,
                  wednesday_close: data.isWednesdayClosed
                    ? 'closed'
                    : data.wednesdayClose,
                  thursday_open: data.isThursdayClosed
                    ? 'closed'
                    : data.thursdayOpen,
                  thursday_close: data.isThursdayClosed
                    ? 'closed'
                    : data.thursdayClose,
                  friday_open: data.isFridayClosed ? 'closed' : data.fridayOpen,
                  friday_close: data.isFridayClosed
                    ? 'closed'
                    : data.fridayClose,
                  saturday_open: data.isSaturdayClosed
                    ? 'closed'
                    : data.saturdayOpen,
                  saturday_close: data.isSaturdayClosed
                    ? 'closed'
                    : data.saturdayClose,
                  sunday_open: data.isSundayClosed ? 'closed' : data.sundayOpen,
                  sunday_close: data.isSundayClosed
                    ? 'closed'
                    : data.sundayClose,
                }
              : {},
          vehicleLoadingTimeWindows: {
            monday_open: data.hasNoOperationsOnMonday
              ? 'closed'
              : data.loadingMondayOpen,
            monday_close: data.hasNoOperationsOnMonday
              ? 'closed'
              : data.loadingMondayClose,
            tuesday_open: data.hasNoOperationsOnTuesday
              ? 'closed'
              : data.loadingTuesdayOpen,
            tuesday_close: data.hasNoOperationsOnTuesday
              ? 'closed'
              : data.loadingTuesdayClose,
            wednesday_open: data.hasNoOperationsOnWednesday
              ? 'closed'
              : data.loadingWednesdayOpen,
            wednesday_close: data.hasNoOperationsOnWednesday
              ? 'closed'
              : data.loadingWednesdayClose,
            thursday_open: data.hasNoOperationsOnThursday
              ? 'closed'
              : data.loadingThursdayOpen,
            thursday_close: data.hasNoOperationsOnThursday
              ? 'closed'
              : data.loadingThursdayClose,
            friday_open: data.hasNoOperationsOnFriday
              ? 'closed'
              : data.loadingFridayOpen,
            friday_close: data.hasNoOperationsOnFriday
              ? 'closed'
              : data.loadingFridayClose,
            saturday_open: data.hasNoOperationsOnSaturday
              ? 'closed'
              : data.loadingSaturdayOpen,
            saturday_close: data.hasNoOperationsOnSaturday
              ? 'closed'
              : data.loadingSaturdayClose,
            sunday_open: data.hasNoOperationsOnSunday
              ? 'closed'
              : data.loadingSundayOpen,
            sunday_close: data.hasNoOperationsOnSunday
              ? 'closed'
              : data.loadingSundayClose,
          },
          dispatchCutoffTime: data.despatchCutoffTime,
          eavesHeightUnitOfMeasure: data.structuralUoM,
          minEavesHeightFeet:
            data.structuralUoM === 'Foot'
              ? data.minEavesHeightFeet
              : data.minEavesHeightFeet
              ? data.minEavesHeightFeet * 3.2808
              : null,
          minEavesHeightMetres:
            data.structuralUoM === 'Metre'
              ? data.minEavesHeightFeet
              : data.minEavesHeightFeet
              ? data.minEavesHeightFeet / 3.2808
              : null,
          constructionYear: data.constructionYear,
          grossInternalAreaUnitOfMeasure:
            data.structuralUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
          grossInternalAreaSquareFeet:
            data.structuralUoM === 'Foot'
              ? data.grossInternalAreaSquareFeet
              : data.grossInternalAreaSquareFeet
              ? data.grossInternalAreaSquareFeet * 10.764
              : null,
          grossInternalAreaSquareMetres:
            data.structuralUoM === 'Metre'
              ? data.grossInternalAreaSquareFeet
              : data.grossInternalAreaSquareFeet
              ? data.grossInternalAreaSquareFeet / 10.764
              : null,
          mezzanineAreaUnitOfMeasure:
            data.structuralUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
          mezzanineAreaSquareFeet:
            data.structuralUoM === 'Foot'
              ? data.mezzanineAreaSquareFeet
              : data.mezzanineAreaSquareFeet
              ? data.mezzanineAreaSquareFeet * 10.764
              : null,
          mezzanineAreaSquareMetres:
            data.structuralUoM === 'Metre'
              ? data.mezzanineAreaSquareFeet
              : data.mezzanineAreaSquareFeet
              ? data.mezzanineAreaSquareFeet / 10.764
              : null,
          isRackingAvailable: data.isRackingAvailable,
          individualRackDepth: data.individualRackDepth,
          individualRackHeight: data.individualRackHeight,
          individualRackWidth: data.individualRackWidth,
          totalRackHeight: data.totalRackHeight,
          storageCapabilities: data.storageCapabilities,
          materialHandlingEquipment: data.materialHandlingEquipment,
          yardSpaceUnitOfMeasure:
            data.accessSpaceUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
          yardSpaceSquareMetres:
            data.accessSpaceUoM === 'Foot'
              ? data.yardSpace
                ? data.yardSpace / 10.764
                : null
              : data.yardSpace,
          yardSpaceSquareFeet:
            data.accessSpaceUoM === 'Foot'
              ? data.yardSpace
              : data.yardSpace
              ? data.yardSpace * 10.764
              : null,
          vehicleAccessHeightUnitOfMeasure: data.accessSpaceUoM,
          vehicleAccessHeightFeet:
            data.accessSpaceUoM === 'Foot'
              ? data.vehicleAccessHeight
              : data.vehicleAccessHeight
              ? data.vehicleAccessHeight * 3.2808
              : null,
          vehicleAccessHeightMetres:
            data.accessSpaceUoM === 'Foot'
              ? data.vehicleAccessHeight
                ? data.vehicleAccessHeight / 3.2808
                : null
              : data.vehicleAccessHeight,
          leveledLoadingDocks: data.leveledLoadingDocks,
          doubleHeightLoadingDocks: data.doubleHeightLoadingDocks,
          carParkingSpaces: data.carParkingSpaces,
          lorryParkingSpaces: data.lorryParkingSpaces,
          sectorExperience: data.sectorExperience,
          noteableCustomers: data.sectorExperience.includes('Other')
            ? [
                data.notableCustomer1,
                data.notableCustomer2,
                data.notalbeCustomer3,
                data.notableCustomer4,
              ]
            : [],
          warehouseOperations: data.warehouseOperations,
          fulfilmentServices: data.fulfilmentServices,
          fleet: {
            MultiAxleArticMoreThan26t: data.MultiAxleArticMoreThan26t,
            ThreeAxleRigidLessThan26t: data.TwoAxleRigidLessThan26t,
            TwoAxleRigid18t: data.TwoAxleRigid18t,
            TwoAxleRigidLessThan7AndHalf5t: data.TwoAxleRigidLessThan7AndHalf5t,
            LgvLessThan3AndHalft: data.LgvLessThan3AndHalft,
            VanLessThan1AndHalft: data.VanLessThan1AndHalft,
            SpecialOrOther: data.SpecialOrOther,
          },
          additionalServices: data.additionalServices,
          safetyAndSecurity: data.safetyAndSecurity,
          systemsAvailable: data.systemsAvailable,
          accreditationsAndMemberships:
            data.accreditationsAndMemberships.includes('None')
              ? []
              : data.accreditationsAndMemberships,
          deletedImageIds: data.deletedImagesIds,
        },
        images: data.siteImages,
        deletedImageIds: data.deletedImagesIds,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (warehouseData) {
      fetchWarehouse();
      setIsUpdateWarehouseModalOpen(false);
      setIsDelistWarehouseModalOpen(false);
    }

    return warehouseData;
  };

  const handleAddToShortlist = async () => {
    setIsAddingToShortlist(true);
    try {
      const { data: warehouseMatch } = await authHttp.post(
        ADD_WAREHOUSE_MATCH,
        {
          match: {
            warehouseId: warehouse.warehouseId,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (warehouseMatch) {
        setIsOnShortlist(true);
        setMatch(warehouseMatch.match);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsAddingToShortlist(false);
    }
  };

  const handleProposal = async (data: ProposalFormValues) => {
    console.log(data);
    if (!businessProfile) {
      setIsNoBusinessProfileOpen(true);
    } else {
      setIsProposing(true);
      try {
        const { data: proposal } = await authHttp.post(
          PROPOSE_WAREHOUSE_MATCH_DETAILS,
          {
            match: {
              matchId: match?.id,
              desiredStartDate: dayjs(data.desiredStartDate).format(
                'YYYY-MM-DD'
              ),
              averageStorageQuantityPerWeek: data.averageStorageQuantityPerWeek,
              estimatedDurationInMonths: data.estimatedDurationInMonths,
              averageNumberOfInboundShipmentsPerWeek:
                data.averageNumberOfInboundShipments,
              averageNumberOfOutboundShipmentsPerWeek:
                data.averageNumberOfOutboundShipments,
              shipperIndustry:
                isWarehouseClient(user) && businessProfile?.industry
                  ? businessProfile.industry
                  : '',
              requestedServices: [],
              serviceRates: data.volumeBasedDiscount
                ? null
                : {
                    storagePricePerWeek: data.storagePricePerWeek,
                    palletReceiptAndPutAwayPrice:
                      data.palletReceiptAndPutAwayPrice,
                    palletPickingAndDespatchPrice:
                      data.palletPickingAndDespatchPrice,
                    palletLayerPickingAndDespatchPrice:
                      data.palletLayerPickingAndDespatchPrice,
                    casePickingAndDespatchPrice:
                      data.casePickingAndDespatchPrice,
                    subsequentCasePickingAndDespatchPrice:
                      data.subsequentCasePickingAndDespatchPrice,
                    itemPickingAndDespatchPrice:
                      data.itemPickingAndDespatchPrice,
                    subsequentItemPickingAndDespatchPrice:
                      data.subsequentItemPickingAndDespatchPrice,
                    containerHandlingPrice: data.containerHandlingPrice,
                    demurragePricePerDay: data.demurragePricePerDay,
                    palletReturnsPrice: data.palletReturnsPrice,
                    itemReturnsPrice: data.itemReturnsPrice,
                    caseReturnsPrice: data.caseReturnsPrice,
                    auxiliaryServicePricePerManHour:
                      data.auxiliaryServicePricePerManHour,
                    additionalSurchargesPercentage:
                      data.additionalSurchargesPercentage,
                  },
              volumeDiscounts: data.volumeBasedDiscount
                ? [
                    {
                      id: match?.volumeDiscounts?.[0]?.id,
                      maxTotalMonthlyTurnover: data.monthlyTurnoverOne,
                      minTotalMonthlyTurnover: 0,
                      discountPercentage: data.monthlyDiscountOne,
                    },
                    ...((data.monthlyTurnoverTwo && data.monthlyDiscountTwo) ||
                    match?.volumeDiscounts?.[1]?.id
                      ? [
                          {
                            id: match?.volumeDiscounts?.[1]?.id,
                            maxTotalMonthlyTurnover:
                              data.monthlyTurnoverTwo || 0,
                            minTotalMonthlyTurnover:
                              +data.monthlyTurnoverOne + 1,
                            discountPercentage: data.monthlyDiscountTwo || 0,
                          },
                        ]
                      : []),
                    ...((data.monthlyTurnoverTwo &&
                      data.monthlyDiscountThree) ||
                    match?.volumeDiscounts?.[2]?.id
                      ? [
                          {
                            id: match?.volumeDiscounts?.[2]?.id,
                            minTotalMonthlyTurnover:
                              +data.monthlyTurnoverTwo + 1 || 0,
                            discountPercentage: data.monthlyDiscountThree || 0,
                          },
                        ]
                      : []),
                  ]
                : null,
              billingCycleFrequency: data.billingCycleFrequency,
              paymentTermsInDays: data.paymentTermsInDays,
              comment: data.comment,
            },
          },
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (proposal) {
          setMatch(proposal.match);
          setIsClientServiceRatesSideDrawerOpen(false);
          setIsManagerServiceRatesSideDrawerOpen(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsProposing(false);
      }
    }
  };

  const handleAcceptMatch = async () => {
    if (!businessProfile) {
      setIsNoBusinessProfileOpen(true);
    } else {
      setIsAccepting(true);
      try {
        const { data: proposal } = await authHttp.post(
          ACCEPT_WAREHOUSE_MATCH,
          {
            match: {
              matchId: match?.id,
            },
          },
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (proposal) {
          setMatch(proposal.match);
          setIsOnShortlist(false);
          setIsRatesConfirmModalOpen(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsAccepting(false);
      }
    }
  };

  const handleDeleteMatch = async (matchIdToDelete: string) => {
    try {
      const { data: matchDeleted } = await authHttp.post(
        DELETE_WAREHOUSE_MATCH,
        {
          match: {
            matchId: matchIdToDelete,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (matchDeleted.match) {
        setIsOnShortlist(false);
        setMatch(null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'unset', md: 'space-between' },
        }}
        padding={24}
        gap={24}
      >
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'unset', md: 'space-between' },
            alignItems: 'center',
          }}
          gap={12}
        >
          <Heading as='h2' size='md'>
            Warehouse in {warehouse.city ? warehouse.city : warehouse.address}
          </Heading>
          {hasUkwaAccreditation && (
            <img src={ukwaLogo} alt='UKWA logo' width='100' />
          )}
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'unset', md: 'space-between' },
          }}
          gap={12}
        >
          {isWarehouseManager(user) ? (
            <Stack direction='row' gap={12}>
              {warehouse.isDelisted ? (
                <Tooltip title='Delisted'>
                  <NoSymbolIcon
                    className='mr-2 inline h-6 w-6 shrink-0'
                    style={{ color: 'red' }}
                  />
                </Tooltip>
              ) : (
                <Button
                  variant='contained'
                  size='large'
                  sx={{ textTransform: 'none' }}
                  onClick={handleDelistWarehouse}
                >
                  Delist warehouse
                </Button>
              )}
              <Button
                variant='contained'
                size='large'
                sx={{ textTransform: 'none' }}
                onClick={handleUpdateWarehouse}
              >
                Update warehouse
              </Button>
              {matchId &&
                match?.matchStatus !== 'Reviewing T&Cs' &&
                match?.matchStatus !== 'Contracted' && (
                  <Button
                    variant='contained'
                    size='large'
                    sx={{
                      textTransform: 'none',
                      bgcolor: '#000066',
                      ':hover': { bgcolor: '#000066' },
                    }}
                    onClick={() => setIsManagerServiceRatesSideDrawerOpen(true)}
                  >
                    Open Rates Proposal
                  </Button>
                )}
            </Stack>
          ) : (
            match?.matchStatus !== 'Contracted' &&
            (user ? (
              <Button
                variant='contained'
                size='large'
                color='primary'
                sx={{
                  textTransform: 'none',
                  bgcolor: '#000066',
                  ':hover': { bgcolor: '#000066' },
                }}
                onClick={() => setIsClientServiceRatesSideDrawerOpen(true)}
              >
                Open Rates Negotiations
              </Button>
            ) : (
              <Button
                variant='contained'
                size='large'
                color='primary'
                target='_top'
                rel='noopener noreferrer'
                href={shipperButtonHref}
                sx={{ textTransform: 'none' }}
              >
                Contact warehouse
              </Button>
            ))
          )}
          {/* <Button
            variant='contained'
            size='large'
            sx={{ textTransform: 'none' }}
            color='error'
            // onClick={handleUpdateWarehouse}
          >
            Delist warehouse
          </Button> */}
        </Stack>
      </Stack>
      <Map
        apikey={HERE_APIKEY}
        mapPosition={{
          lat: warehouse.location.lat,
          lng: warehouse.location.lon,
        }}
        warehouseData={{ warehouses: [warehouse] }}
        disablePanning
        height={isMobile ? '200px' : '750px'}
      />
      <View>
        <Stack spacing={48}>
          {/* General Info */}
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
            }}
            gap={36}
            mt={24}
          >
            <Stack
              sx={{
                width: { xs: '100%', md: 'unset' },
                maxWidth: { xs: '100%', md: '400px' },
              }}
              gap={12}
            >
              {images.length === 0 ? (
                <Box
                  component='img'
                  sx={{
                    height: 255,
                    display: 'block',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: '100%',
                    minWidth: 200,
                  }}
                  src={warehouseImagePlaceholder}
                />
              ) : images.length > 1 ? (
                <>
                  <SwipeableViews
                    enableMouseEvents
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                  >
                    {images.map((step, index) => (
                      <Box
                        key={index}
                        component='img'
                        sx={{
                          height: 255,
                          display: 'block',
                          maxWidth: 400,
                          overflow: 'hidden',
                          width: '100%',
                          minWidth: 200,
                        }}
                        src={step.imgPath}
                      />
                    ))}
                  </SwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position='static'
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size='small'
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button
                        size='small'
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        <KeyboardArrowLeft />
                      </Button>
                    }
                    sx={{ backgroundColor: 'var(--colors-gray1)' }}
                  />
                </>
              ) : (
                <Box
                  component='img'
                  sx={{
                    height: 255,
                    display: 'block',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: '100%',
                    minWidth: 200,
                  }}
                  src={images[0].imgPath}
                />
              )}
              {warehouse.modifiedAt && (
                <Typography>
                  Last updated:{' '}
                  {moment(warehouse.modifiedAt).format('DD/MM/YYYY')}
                </Typography>
              )}
            </Stack>
            <Stack spacing={24} sx={{ maxWidth: { xs: '100%', md: '33%' } }}>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Company name
                </Heading>
                <Typography>
                  {warehouse.businessName ?? warehouse.userBusinessName}
                </Typography>
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Contact name
                </Heading>
                <Typography>{warehouse.businessContactName}</Typography>
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Address
                </Heading>
                <Typography>
                  {warehouse.city ? warehouse.city : warehouse.address}
                </Typography>
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Description
                </Heading>
                <Typography>{warehouse.description}</Typography>
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Temperature
                </Heading>
                {!warehouse.temperatureControlTypes.length ? (
                  <Typography>N/A</Typography>
                ) : (
                  warehouse.temperatureControlTypes.map((type) => (
                    <Typography key={type}>{type}</Typography>
                  ))
                )}
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Temperature monitoring capabilities
                </Heading>
                {!warehouse.temperatureMonitoringCapeabilities.length ? (
                  <Typography>N/A</Typography>
                ) : (
                  warehouse.temperatureMonitoringCapeabilities.map((type) => (
                    <Typography key={type}>{type}</Typography>
                  ))
                )}
              </Stack>
            </Stack>
            <Stack spacing={24}>
              <Stack flex='1'>
                <Typography fontSize='1.25em' fontWeight='bold'>
                  Accreditations and memberships
                </Typography>
                {warehouse.accreditationsAndMemberships.length === 0 ? (
                  <Typography>No formal accreditations</Typography>
                ) : (
                  warehouse.accreditationsAndMemberships.map((type) => {
                    return type === 'UKWA' ? (
                      <Stack key={type} direction='row' alignItems='center'>
                        <img
                          src={ukwaLogo}
                          alt='UKWA logo'
                          width='100'
                          height='50'
                        />
                      </Stack>
                    ) : (
                      <Typography key={type} mt={4}>
                        {type}
                      </Typography>
                    );
                  })
                )}
              </Stack>
              <Stack flex='1'>
                {warehouse.noteableCustomers.some(
                  (customer) => customer !== null
                ) && (
                  <>
                    <Typography fontSize='1.25em' fontWeight='bold'>
                      Notable customers
                    </Typography>
                    {warehouse.noteableCustomers.map((type) => (
                      <Typography key={type} mt={4}>
                        {type}
                      </Typography>
                    ))}
                  </>
                )}
              </Stack>
            </Stack>
            {isWarehouseClient(user) &&
              (match?.matchStatus === 'Contracted' ? (
                <>
                  <Stack spacing={24} sx={{ minWidth: '20%' }}>
                    <Typography fontSize='1.25em' fontWeight='bold'>
                      Agreed Service Rates
                    </Typography>
                    <PriceDisplay
                      label={`Pallet Storage (per
                                ${
                                  warehouse.storageCapacityUnitOfMeasure ===
                                  'Pallets'
                                    ? 'pallet-week'
                                    : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                                }
                                )`}
                      price={
                        match?.serviceRates?.storagePricePerWeek
                          ?.acceptedValue ??
                        match?.originalRates?.storagePricePerWeek
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Pallet Receipt and Putaway (per pallet)'
                      price={
                        match?.serviceRates?.palletReceiptAndPutAwayPrice
                          ?.acceptedValue ??
                        match?.originalRates?.palletReceiptAndPutAwayPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Pallet Picking and Despatch (per pallet)'
                      price={
                        match?.serviceRates?.palletPickingAndDespatchPrice
                          ?.acceptedValue ??
                        match?.originalRates?.palletPickingAndDespatchPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Case Picking and Despatch (per case)'
                      price={
                        match?.serviceRates?.casePickingAndDespatchPrice
                          ?.acceptedValue ??
                        match?.originalRates?.casePickingAndDespatchPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Item Picking and Despatch (per item)'
                      price={
                        match?.serviceRates?.itemPickingAndDespatchPrice
                          ?.acceptedValue ??
                        match?.originalRates?.itemPickingAndDespatchPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Container Handling (per container)'
                      price={
                        match?.serviceRates?.containerHandlingPrice
                          ?.acceptedValue ??
                        match?.originalRates?.containerHandlingPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Demurrage (per consignment day)'
                      price={
                        match?.serviceRates?.demurragePricePerDay
                          ?.acceptedValue ??
                        match?.originalRates?.demurragePricePerDay
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Pallet Returns (per pallet)'
                      price={
                        match?.serviceRates?.palletReturnsPrice
                          ?.acceptedValue ??
                        match?.originalRates?.palletReturnsPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Item Returns (per item)'
                      price={
                        match?.serviceRates?.itemReturnsPrice?.acceptedValue ??
                        match?.originalRates?.itemReturnsPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Case Returns (per case)'
                      price={
                        match?.serviceRates?.caseReturnsPrice?.acceptedValue ??
                        match?.originalRates?.caseReturnsPrice
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Auxiliary services (per man-hour)'
                      price={
                        match?.serviceRates?.auxiliaryServicePricePerManHour
                          ?.acceptedValue ??
                        match?.originalRates?.auxiliaryServicePricePerManHour
                      }
                      currencySymbol={currencySymbol ?? '£'}
                    />
                    <PriceDisplay
                      label='Additional surcharges (additional %-ge to the base)'
                      price={
                        match?.serviceRates?.additionalSurchargesPercentage
                          ?.acceptedValue
                          ? `${match.serviceRates.additionalSurchargesPercentage.acceptedValue}%`
                          : `${match?.originalRates?.additionalSurchargesPercentage}%`
                      }
                      currencySymbol=''
                    />
                  </Stack>
                  <Stack spacing={24}>
                    <div>
                      <p className='mb-4'>
                        All prices listed above are exclusive of VAT and FLOX
                        transaction fees. FLOX transaction fees for the services
                        are as follows:
                      </p>
                      <ul>
                        <li>Storage: 0%</li>
                        <li>Handling: 0%</li>
                        <li>Other Services: 0%</li>
                        <li>Haulage (FTL/LTL): 0%</li>
                        <li>Urban Distribution: 0%</li>
                        <li>
                          Additional financial transaction fees vary between 0%
                          and 1.75%, depending on the selected payment option.
                        </li>
                      </ul>
                    </div>
                  </Stack>
                </>
              ) : (
                <Stack flex='1' alignItems='end'>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    sx={{
                      textTransform: 'none',
                      bgcolor: '#000066',
                      ':hover': { bgcolor: '#000066' },
                      width: 'max-content',
                    }}
                    onClick={() => setIsClientServiceRatesSideDrawerOpen(true)}
                  >
                    Open Rates Negotiations
                  </Button>
                </Stack>
              ))}
            {isWarehouseManager(user) && (
              <Stack spacing={24}>
                <Typography fontSize='1.25em' fontWeight='bold'>
                  {match?.matchStatus === 'Contracted'
                    ? 'Agreed Service Rates'
                    : 'Service Rates'}
                </Typography>
                <PriceDisplay
                  label={`Pallet Storage (per
                  ${
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? 'pallet-week'
                      : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                  }
                  )`}
                  price={
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? warehouse.palletStoragePricePerWeek
                      : warehouse.storageCapacityUnitOfMeasure ===
                        'Square Metre'
                      ? warehouse.squareMetreStoragePricePerWeek
                      : warehouse.squareFeetStoragePricePerWeek
                  }
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Pallet Receipt and Putaway'
                  price={warehouse.palletReceiptAndPutAwayPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Pallet Picking and Despatch'
                  price={warehouse.palletPickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Pallet Layer Picking and Despatch'
                  price={warehouse.palletLayerPickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Case Picking and Despatch'
                  price={warehouse.casePickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Subsequent Case Picking and Despatch'
                  price={warehouse.subsequentCasePickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Item Picking and Despatch'
                  price={warehouse.itemPickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Subsequent Item Picking and Despatch'
                  price={warehouse.subsequentItemPickingAndDespatchPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Container Handling'
                  price={warehouse.containerHandlingPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Demurrage (per day)'
                  price={warehouse.demurragePricePerDay}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Pallet Returns'
                  price={warehouse.palletReturnsPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Item Returns'
                  price={warehouse.itemReturnsPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Case Returns'
                  price={warehouse.caseReturnsPrice}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Auxiliary services'
                  price={warehouse.auxiliaryServicePricePerManHour}
                  currencySymbol={currencySymbol ?? '£'}
                />
                <PriceDisplay
                  label='Additional surcharges'
                  price={`${warehouse.additionalSurchargesPercentage}%`}
                  currencySymbol=''
                />
              </Stack>
            )}
          </Stack>
          {/* Working hours */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Hours
            </Heading>
          </Stack>
          <Stack gap={24}>
            <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Working hours
                </Heading>
                {warehouse.standardBusinessHoursType === 'Standard24/7' ? (
                  <Stack>
                    <Stack direction='row' mb={4} mt={4}>
                      <Typography flex='1'>Monday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Tuesday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Wednesday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Thursday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Friday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Saturday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Sunday</Typography>
                      <Typography flex='1'>00:00 - 23:59</Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack direction='row' mb={4} mt={4}>
                      <Typography flex='1'>Monday</Typography>
                      {warehouse.customBusinessHours.monday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.monday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {(
                            warehouse.customBusinessHours.monday_open ?? ''
                          ).slice(0, 5)}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.monday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Tuesday</Typography>
                      {warehouse.customBusinessHours.tuesday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.tuesday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.tuesday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.tuesday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Wednesday</Typography>
                      {warehouse.customBusinessHours.wednesday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.wednesday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.wednesday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.wednesday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Thursday</Typography>
                      {warehouse.customBusinessHours.thursday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.thursday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.thursday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.thursday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Friday</Typography>
                      {warehouse.customBusinessHours.friday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.friday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.friday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.friday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Saturday</Typography>
                      {warehouse.customBusinessHours.saturday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.saturday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.saturday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.saturday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction='row' mb={4}>
                      <Typography flex='1'>Sunday</Typography>
                      {warehouse.customBusinessHours.sunday_open ===
                        '00:00:00' &&
                      warehouse.customBusinessHours.sunday_close ===
                        '00:00:00' ? (
                        <Typography flex='1'>Closed</Typography>
                      ) : (
                        <Typography flex='1'>
                          {' '}
                          {warehouse.customBusinessHours.sunday_open?.slice(
                            0,
                            5
                          )}{' '}
                          -{' '}
                          {warehouse.customBusinessHours.sunday_close?.slice(
                            0,
                            5
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Stack flex='1'>
                <Heading as='h2' size='xs'>
                  Loading hours
                </Heading>
                <Stack>
                  <Stack direction='row' mb={4} mt={4}>
                    <Typography flex='1'>Monday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.monday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.monday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {warehouse.vehicleLoadingTimeWindows.monday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.monday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Tuesday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.tuesday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.tuesday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.tuesday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.tuesday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Wednesday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.wednesday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.wednesday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.wednesday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.wednesday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Thursday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.thursday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.thursday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.thursday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.thursday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Friday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.friday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.friday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.friday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.friday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Saturday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.saturday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.saturday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.saturday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.saturday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='row' mb={4}>
                    <Typography flex='1'>Sunday</Typography>
                    {warehouse.vehicleLoadingTimeWindows.sunday_open ===
                      '00:00:00' &&
                    warehouse.vehicleLoadingTimeWindows.sunday_close ===
                      '00:00:00' ? (
                      <Typography flex='1'>Closed</Typography>
                    ) : (
                      <Typography flex='1'>
                        {' '}
                        {warehouse.vehicleLoadingTimeWindows.sunday_open?.slice(
                          0,
                          5
                        )}{' '}
                        -{' '}
                        {warehouse.vehicleLoadingTimeWindows.sunday_close?.slice(
                          0,
                          5
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Despatch cutoff time
              </Typography>
              <Typography>
                {warehouse.dispatchCutoffTime?.slice(0, 5)}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Has flexible business hours?
              </Typography>
              <Typography>
                {warehouse.hasFlexibleBusinessHours ? 'Yes' : 'No'}
              </Typography>
            </Stack>
          </Stack>
          {/* Capacity */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Capacity
            </Heading>
          </Stack>
          <Stack
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            gap={24}
            justifyContent='space-between'
          >
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Max storage capacity
              </Typography>
              <Typography>
                {warehouse.maxStorageCapacity}{' '}
                {warehouse.storageCapacityUnitOfMeasure?.toLowerCase()}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Max storage capacity available
              </Typography>
              <Typography>
                {warehouse.maxStorageCapacityAvailable}{' '}
                {warehouse.storageCapacityUnitOfMeasure?.toLowerCase()}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Currently available capacity for new customers
              </Typography>
              <Typography>
                {warehouse.currentlyAvailableCapacity}{' '}
                {warehouse.storageCapacityUnitOfMeasure?.toLowerCase()}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Min storage capacity threshold
              </Typography>
              <Typography>
                {warehouse.minStorageCapacityThreshold}{' '}
                {warehouse.storageCapacityUnitOfMeasure?.toLowerCase()}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                Min storage time threshold
              </Typography>
              <Typography>
                {warehouse.minStorageTimeThreshold}{' '}
                {warehouse.minStorageTimeThreshold > 1
                  ? `${warehouse.storageTimeUnitOfMeasure?.toLowerCase()}s`
                  : warehouse.storageTimeUnitOfMeasure?.toLowerCase()}
              </Typography>
            </Stack>
          </Stack>
          {/* Facilities */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Facilities
            </Heading>
          </Stack>
          <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Safety and security
              </Heading>
              {SAFETY_AND_SECURITY.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.safetyAndSecurity.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.safetyAndSecurity.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Special capabilities
              </Heading>
              {STORAGE_CAPABILITIES.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.storageCapabilities.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.storageCapabilities.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Material handling equipment
              </Heading>
              {MATERIAL_HANDLING_EQUIPMENT.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.materialHandlingEquipment.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.materialHandlingEquipment.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {/* Services */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Services
            </Heading>
          </Stack>
          <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Warehouse operations
              </Heading>
              {WAREHOUSE_OPERATIONS.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.warehouseOperations.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.warehouseOperations.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Additional services
              </Heading>
              {ADDITIONAL_SERVICES.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.additionalServices.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.additionalServices.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack flex='1'>
              <Heading as='h2' size='xs' css={{ marginBottom: '1rem' }}>
                Fulfilment services
              </Heading>
              {FULFILMENT_SERVICES.map((type) => (
                <Stack key={type} direction='row' gap={12}>
                  {warehouse.fulfilmentServices.includes(type) ? (
                    <DoneIcon />
                  ) : (
                    <CloseIcon style={{ color: 'lightgrey' }} />
                  )}

                  <Typography
                    sx={{
                      color: warehouse.fulfilmentServices.includes(type)
                        ? 'black'
                        : 'lightgrey',
                    }}
                  >
                    {type}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {/* Specification */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Warehouse specification
            </Heading>
          </Stack>
          <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
            <Stack flex='1' spacing={24}>
              {(warehouse.grossInternalAreaSquareFeet !== 0 ||
                warehouse.grossInternalAreaSquareMetres !== 0) && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    GIA (Gross Internal Area)
                  </Typography>
                  {warehouse.grossInternalAreaUnitOfMeasure ===
                  'Square Foot' ? (
                    <Typography>
                      {warehouse.grossInternalAreaSquareFeet.toFixed(2)} sq.ft
                    </Typography>
                  ) : (
                    <Typography>
                      {warehouse.grossInternalAreaSquareMetres.toFixed(2)} sq.m
                    </Typography>
                  )}
                </Stack>
              )}
              {warehouse.constructionYear !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Construction year
                  </Typography>
                  <Typography>{warehouse.constructionYear}</Typography>
                </Stack>
              )}
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Racking available?
                </Typography>
                <Typography>
                  {warehouse.isRackingAvailable ? 'Yes' : 'No'}
                </Typography>
              </Stack>
              {warehouse.isRackingAvailable && (
                <>
                  <Stack>
                    <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                      Racking width
                    </Typography>
                    <Typography>{warehouse.individualRackWidth}</Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                      Racking height
                    </Typography>
                    <Typography>{warehouse.individualRackHeight}</Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                      Racking depth
                    </Typography>
                    <Typography>{warehouse.individualRackDepth}</Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                      Total rack height
                    </Typography>
                    <Typography>{warehouse.totalRackHeight}</Typography>
                  </Stack>
                </>
              )}
              {(warehouse.minEavesHeightFeet !== 0 ||
                warehouse.minEavesHeightMetres !== 0) && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Minimum eaves height
                  </Typography>
                  {/* Even though eave height is feet or metres, the UoM is square foot or square metre */}
                  {warehouse.eavesHeightUnitOfMeasure === 'Square Foot' ? (
                    <Typography>
                      {warehouse.minEavesHeightFeet.toFixed(2)} ft
                    </Typography>
                  ) : (
                    <Typography>
                      {warehouse.minEavesHeightMetres.toFixed(2)} metres
                    </Typography>
                  )}
                </Stack>
              )}
              {(warehouse.vehicleAccessHeightFeet !== 0 ||
                warehouse.vehicleAccessHeightMetres !== 0) && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Vehicle access height
                  </Typography>
                  {warehouse.vehicleAccessHeightUnitOfMeasure ===
                  'Square Foot' ? (
                    <Typography>
                      {warehouse.vehicleAccessHeightFeet.toFixed(2)} ft
                    </Typography>
                  ) : (
                    <Typography>
                      {warehouse.vehicleAccessHeightMetres.toFixed(2)} metres
                    </Typography>
                  )}
                </Stack>
              )}
              {(warehouse.yardSpaceSquareFeet !== 0 ||
                warehouse.yardSpaceSquareMetres !== 0) && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Yard space
                  </Typography>
                  {warehouse.yardSpaceUnitOfMeasure === 'Square Foot' ? (
                    <Typography>
                      {warehouse.yardSpaceSquareFeet.toFixed(2)} sq.ft
                    </Typography>
                  ) : (
                    <Typography>
                      {warehouse.yardSpaceSquareMetres.toFixed(2)} sq.m
                    </Typography>
                  )}
                </Stack>
              )}
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Onsite office
                </Typography>
                <Typography>
                  {warehouse.hasOnsiteOffice ? 'Yes' : 'No'}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Off dock
                </Typography>
                <Typography>{warehouse.isOffDock ? 'Yes' : 'No'}</Typography>
              </Stack>
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Is quay
                </Typography>
                <Typography>{warehouse.isQuay ? 'Yes' : 'No'}</Typography>
              </Stack>
            </Stack>
            <Stack flex='1' spacing={24}>
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Headcount
                </Typography>
                <Typography>
                  {warehouse.minHeadcount} - {warehouse.maxHeadcount}
                </Typography>
              </Stack>
              {(warehouse.mezzanineAreaSquareFeet !== 0 ||
                warehouse.mezzanineAreaSquareMetres !== 0) && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Mezzanine area
                  </Typography>
                  {warehouse.mezzanineAreaUnitOfMeasure === 'Square Foot' ? (
                    <Typography>
                      {warehouse.mezzanineAreaSquareFeet.toFixed(2)} sq.ft
                    </Typography>
                  ) : (
                    <Typography>
                      {warehouse.mezzanineAreaSquareMetres.toFixed(2)} sq.m
                    </Typography>
                  )}
                </Stack>
              )}
              {warehouse.loadingDocks !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    No. of loading docks
                  </Typography>
                  <Typography>{warehouse.loadingDocks}</Typography>
                </Stack>
              )}
              {warehouse.leveledLoadingDocks !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    No. of levelled loading docks
                  </Typography>
                  <Typography>{warehouse.leveledLoadingDocks}</Typography>
                </Stack>
              )}
              {warehouse.doubleHeightLoadingDocks !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    No. of double height loading docks
                  </Typography>
                  <Typography>{warehouse.doubleHeightLoadingDocks}</Typography>
                </Stack>
              )}
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Lorry parking spaces
                </Typography>
                <Typography>{warehouse.lorryParkingSpaces}</Typography>
              </Stack>
              {warehouse.carParkingSpaces !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Car parking spaces
                  </Typography>
                  <Typography>{warehouse.carParkingSpaces}</Typography>
                </Stack>
              )}
              {warehouse.numberOfShifts !== 0 && (
                <Stack>
                  <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                    Number of shifts
                  </Typography>
                  <Typography>{warehouse.numberOfShifts}</Typography>
                </Stack>
              )}
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Sector experience
                </Typography>
                {warehouse.sectorExperience.map((type) => (
                  <Typography key={type}>{type}</Typography>
                ))}
              </Stack>
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Systems available
                </Typography>
                {warehouse.systemsAvailable.map((type) => (
                  <Typography key={type}>{type}</Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
          {/* Fleet */}
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='sm'>
              Fleet
            </Heading>
          </Stack>
          <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
            <Stack flex='1' spacing={24}>
              <Stack>
                <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                  Has own fleet?
                </Typography>
                <Typography>
                  {Object.keys(warehouse.fleet).length ? 'Yes' : 'No'}
                </Typography>
              </Stack>
              {Object.keys(warehouse.fleet).length > 0 &&
                Object.keys(warehouse.fleet).map((type) => (
                  <Stack key={type}>
                    <Typography fontSize='1.25em' fontWeight='bold' mb={10}>
                      {fleetMapping[type as Fleet] || type}
                    </Typography>
                    <Typography>{warehouse.fleet[type as Fleet]}</Typography>
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Stack>
        <ClientServiceRatesSideDrawer
          warehouse={warehouse}
          match={match}
          isOpen={isClientServiceRatesSideDrawerOpen}
          isAddingToShortlist={isAddingToShortlist}
          isOnShortlist={isOnShortlist}
          isProposing={isProposing}
          isAccepting={isAccepting}
          comment={comment}
          setIsOpen={() => setIsClientServiceRatesSideDrawerOpen(false)}
          handleAddToShortlist={handleAddToShortlist}
          handleProposal={handleProposal}
          handleAcceptMatch={() => setIsRatesConfirmModalOpen(true)}
        />
        <ManagerServiceRatesSideDrawer
          warehouse={warehouse}
          match={match}
          isOnShortlist={isOnShortlist}
          isProposing={isProposing}
          isAccepting={isAccepting}
          comment={comment}
          isOpen={isManagerServiceRatesSideDrawerOpen}
          setIsOpen={() => setIsManagerServiceRatesSideDrawerOpen(false)}
          handleProposal={handleProposal}
          handleDeleteMatch={handleDeleteMatch}
          handleAcceptMatch={() => setIsRatesConfirmModalOpen(true)}
        />
        <Modal
          open={isUpdateWarehouseModalOpen}
          onOpenChange={(isOpen: boolean) => {
            setIsUpdateWarehouseModalOpen(isOpen);
          }}
        >
          <ModalContainer preventCloseOnOutsideClick>
            <ModalHeader title='Update Warehouse' />
            <WarehouseForm
              prefilledValues={{
                ...warehouse,
                temperatureControlTypes: warehouse.temperatureControlTypes[0],
                currency: warehouse.currency ? warehouse.currency : undefined,
                businessName: warehouse.businessName
                  ? warehouse.businessName
                  : warehouse.userBusinessName,
                workingHours: workingHours
                  ? { ...workingHours }
                  : {
                      mondayOpen: '06:00',
                      mondayClose: '18:00',
                      tuesdayOpen: '06:00',
                      tuesdayClose: '18:00',
                      wednesdayOpen: '06:00',
                      wednesdayClose: '18:00',
                      thursdayOpen: '06:00',
                      thursdayClose: '18:00',
                      fridayOpen: '06:00',
                      fridayClose: '18:00',
                      saturdayOpen: '06:00',
                      saturdayClose: '18:00',
                      sundayOpen: '06:00',
                      sundayClose: '18:00',
                    },
                loadingHours: loadingHours
                  ? { ...loadingHours }
                  : {
                      loadingMondayOpen: '06:00',
                      loadingMondayClose: '12:00',
                      loadingTuesdayOpen: '06:00',
                      loadingTuesdayClose: '12:00',
                      loadingWednesdayOpen: '06:00',
                      loadingWednesdayClose: '12:00',
                      loadingThursdayOpen: '06:00',
                      loadingThursdayClose: '12:00',
                      loadingFridayOpen: '06:00',
                      loadingFridayClose: '12:00',
                      loadingSaturdayOpen: '06:00',
                      loadingSaturdayClose: '12:00',
                      loadingSundayOpen: '06:00',
                      loadingSundayClose: '12:00',
                    },
                // ...workingHours,
                // ...loadingHours,
                hasOwnFleet: Object.keys(warehouse.fleet).length > 0,
                ...(Object.keys(warehouse.fleet).length > 0
                  ? warehouse.fleet
                  : {}),
                accreditationsAndMemberships:
                  Object.keys(warehouse.accreditationsAndMemberships).length > 0
                    ? warehouse.accreditationsAndMemberships
                    : ['None'],
                structuralUoM: warehouse.eavesHeightUnitOfMeasure
                  ? warehouse.eavesHeightUnitOfMeasure
                  : undefined,
                accessSpaceUoM: warehouse.vehicleAccessHeightUnitOfMeasure
                  ? warehouse.vehicleAccessHeightUnitOfMeasure
                  : undefined,
                minEavesHeightFeet:
                  warehouse.grossInternalAreaUnitOfMeasure === 'Foot'
                    ? warehouse.minEavesHeightFeet
                    : warehouse.minEavesHeightMetres,
                grossInternalAreaSquareFeet:
                  warehouse.grossInternalAreaUnitOfMeasure === 'Square Foot'
                    ? warehouse.grossInternalAreaSquareFeet
                    : warehouse.grossInternalAreaSquareMetres,
                mezzanineAreaSquareFeet:
                  warehouse.mezzanineAreaUnitOfMeasure === 'Square Foot'
                    ? warehouse.mezzanineAreaSquareFeet
                    : warehouse.mezzanineAreaSquareMetres,
                yardSpace:
                  warehouse.yardSpaceUnitOfMeasure === 'Square Foot'
                    ? warehouse.yardSpaceSquareFeet
                    : warehouse.yardSpaceSquareMetres,
                vehicleAccessHeight:
                  warehouse.vehicleAccessHeightUnitOfMeasure === 'Foot'
                    ? warehouse.vehicleAccessHeightFeet
                    : warehouse.vehicleAccessHeightMetres,
                constructionYear: warehouse.constructionYear
                  ? warehouse.constructionYear
                  : undefined,
                standardBusinessHoursType:
                  warehouse.standardBusinessHoursType === 'Standard 24/5'
                    ? undefined
                    : warehouse.standardBusinessHoursType,
                notableCustomer1: warehouse.noteableCustomers[0],
                notableCustomer2: warehouse.noteableCustomers[1],
                notableCustomer3: warehouse.noteableCustomers[2],
                notableCustomer4: warehouse.noteableCustomers[3],
                TwoAxleRigidLessThan26t:
                  warehouse.fleet.ThreeAxleRigidLessThan26t,
              }}
              handleOnSubmit={handleOnSubmit}
            />
          </ModalContainer>
        </Modal>
        <Modal
          open={isRatesConfirmModalOpen}
          onOpenChange={(isOpen: boolean) => {
            setIsRatesConfirmModalOpen(isOpen);
          }}
        >
          <ModalContainer preventCloseOnOutsideClick style={{ zIndex: 9999 }}>
            <ModalHeader title='Confirm Rates' />
            <ModalContent
              css={{
                maxHeight: 'calc(85vh - 12.5rem)',
              }}
            >
              <Typography>
                By accepting the rates proposal, you are entering into a legally
                binding relationship with the{' '}
                {isWarehouseManager(user) ? 'customer' : 'warehouse'}. This
                contract will be governed by our standard{' '}
                <a
                  href='https://www.flox.is/standard-conditions-of-warehousing-services'
                  className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Warehousing Services Terms & Conditions
                </a>{' '}
                and will be based on the agreed pricing schedule. Please confirm
                your acceptance by e-signing the Pricing Schedule Addendum sent
                to your email address.
              </Typography>
            </ModalContent>
            <ModalFooter>
              <Button
                variant='contained'
                size='large'
                sx={{ textTransform: 'none' }}
                onClick={handleAcceptMatch}
                disabled={
                  isAccepting || match?.matchStatus === 'Reviewing T&Cs'
                }
              >
                {isAccepting
                  ? 'Confirming...'
                  : match?.isActionRequiredByShipper
                  ? 'Confirmed'
                  : 'Confirm Rates'}
              </Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
        <Modal
          open={isNoBusinessProfileOpen}
          onOpenChange={(isOpen: boolean) => {
            setIsNoBusinessProfileOpen(isOpen);
          }}
        >
          <ModalContainer preventCloseOnOutsideClick style={{ zIndex: 9999 }}>
            <ModalHeader title='Action Required: Finalise Your Corporate Details' />
            <ModalContent>
              <Typography variant='body1'>
                To complete the Price Schedule agreement, we need you to
                finalise your corporate details. Please update your Business
                Profile by clicking the button below.
              </Typography>
            </ModalContent>
            <ModalFooter>
              <Button
                variant='contained'
                onClick={() =>
                  navigate(`/profile?warehouseID=${warehouse.warehouseId}`)
                }
              >
                Take me to my Business Profile
              </Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
        <Modal
          open={isDelistWarehouseModalOpen}
          onOpenChange={(isOpen: boolean) => {
            setIsDelistWarehouseModalOpen(isOpen);
          }}
        >
          <ModalContainer style={{ zIndex: 9999 }}>
            <ModalHeader title='Delist Warehouse' />
            <ModalContent>
              <Typography variant='body1'>
                Are you sure you want to delist this warehouse? This action will
                prevent customers from searching for this warehouse and will
                require administrator action to undo.
              </Typography>
              {isDelistWarehouseErrorShowing && (
                <Typography variant='body1' style={{ color: 'red' }}>
                  Something went wrong. Please try again.
                </Typography>
              )}
            </ModalContent>
            <ModalFooter>
              <Button variant='contained' onClick={delistWarehouse}>
                Delist
              </Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
      </View>
    </>
  );
}
