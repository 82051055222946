import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button/Button';
import { BillingCycle, Invoice } from './invoiceTypes';
import { getBillingCycleCost, getBillingCycleVatCost } from './invoiceHelper';

export function InvoicePaymentCard({
  billingCycle,
  invoice,
  isSelected,
  onToggleSelected,
  isPaid,
}: {
  billingCycle: BillingCycle;
  invoice: Invoice;
  isSelected?: boolean;
  isPaid?: boolean;
  onToggleSelected: (billingCycle: BillingCycle, isSelecting: boolean) => void;
}) {
  const navigate = useNavigate();
  const subTotalAmount = getBillingCycleCost(billingCycle);
  const subTotalVatAmount = getBillingCycleVatCost(billingCycle);

  const now = moment();
  const timeRemaining = invoice?.dueDate
    ? moment(invoice.dueDate).diff(now, 'days')
    : null;

  const isOverdue = timeRemaining ? timeRemaining < 0 : false;

  return (
    <div
      className='mt-8 p-8 bg-white rounded-lg sm:p-6 
        shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '50rem',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            className='font-bold mr-6'
            style={{ minWidth: '5rem', textAlign: 'right' }}
          >
            INVOICE:
          </div>
          <Button
            style={{ background: 'none', padding: '0' }}
            onClick={() => navigate(`/invoice/${billingCycle.id}`)}
          >
            {invoice.invoiceNumber}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            className='font-bold mr-6'
            style={{ minWidth: '5rem', textAlign: 'right' }}
          >
            LSP:
          </div>
          <div>{billingCycle.lspBusinessName}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'space-between',
          }}
        >
          <div
            className='font-bold mr-6'
            style={{ minWidth: '5rem', textAlign: 'right' }}
          >
            Services:
          </div>
          <div style={{ minWidth: '30rem', maxWidth: '30rem' }}>
            {invoice.lines.map((line) => line.description).join(', ')}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            className='font-bold'
            style={{ minWidth: '5rem', textAlign: 'right' }}
          >
            Total:
          </div>
          <div className='font-bold'>
            {invoice.lines[0].currencySymbol}
            {(subTotalAmount + subTotalVatAmount).toFixed(2)}
          </div>
        </div>
      </div>
      {!isPaid && (
        <div className='' style={{ maxWidth: '8rem' }}>
          <div>
            {isOverdue ? (
              <div className='font-bold text-red-500'>
                ({timeRemaining} days OD)
              </div>
            ) : (
              <div className='font-bold text-green-500'>
                ({timeRemaining} days)
              </div>
            )}
          </div>
          <input
            type='checkbox'
            checked={isSelected}
            onClick={(_) => onToggleSelected(billingCycle, !isSelected)}
          />
        </div>
      )}
    </div>
  );
}
