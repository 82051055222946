import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@components/Heading';
import { View } from '@views/View';
import {
  Board,
  BoardLane,
  BoardLaneHeader,
  BoardLaneTitleContainer,
  BoardLaneTitleButton,
  BoardLaneItemCount,
} from '@components/Board';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DialogModal } from '@components/DialogModal';
import InfiniteScroll from 'react-infinite-scroller';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoading,
  InfiniteScrollErrorMessage,
} from '@components/InfiniteScroll';
import {
  InboundSupplyChainProcessEventList,
  OutboundSupplyChainProcessEventList,
  SupplyChainProcessEventCard,
} from '@components/SupplyChainProcessEventList';
import { CreateInboundShipmentNoteForm } from '@forms/client/CreateInboundShipmentNoteForm';
import {
  useInboundProcess,
  useInboundShipmentNote,
  useDespatchNote,
  useOutboundProcess,
} from '@hooks';
import { InvoiceEventList } from '@components/InvoiceEventList/InvoiceEventList';
import { InvoicingEventCard } from '@components/InvoiceEventList/InvoiceEventCard';
import { CreateDespatchNoteForm } from '@forms/client/CreateDespatchNoteForm';
import { useAtom, useSetAtom } from 'jotai';
import { WarehouseSelector } from '@components/WarehouseSelector';
import { useAuth } from '@context';
import { ShipmentNoteDetails } from './ShipmentNoteDetails';
import { GoodsReceivedNoteDetails } from './GoodsReceivedNoteDetails';
import { WarehouseClientInboundSideDrawer } from './WarehouseClientInboundSideDrawer';
import { WarehouseClientOutboundSideDrawer } from './WarehouseClientOutboundSideDrawer';
import { DespatchNoteDetails } from './DespatchNoteDetails';
import { PickingConfirmationDetails } from './PickingConfirmationDetails';
import { OutboundShipmentNoteDetails } from './OutboundShipmentNoteDetails';
import { ProofOfDeliveryDetails } from './ProofOfDeliveryDetails';
import {
  isInboundSidebarOpenAtom,
  isOutboundSidebarOpenAtom,
  selectedInboundProcessIdAtom,
  selectedOutboundProcessIdAtom,
} from '../../../store/jotai';
import { useInvoices } from '../../../hooks/useInvoices';

export function WarehouseClientNoticeboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const [isInboundSidebarOpen, setIsInboundSidebarOpen] = useAtom(
    isInboundSidebarOpenAtom
  );
  const [isOutboundSidebarOpen, setIsOutboundSidebarOpen] = useAtom(
    isOutboundSidebarOpenAtom
  );
  const setSelectedInboundProcessId = useSetAtom(selectedInboundProcessIdAtom);
  const setSelectedOutboundProcessId = useSetAtom(
    selectedOutboundProcessIdAtom
  );
  const searchParams = new URLSearchParams(window.location.search);

  const supplyChainProcessId = searchParams.get('supplyChainProcessId');
  const processChildType = searchParams.get('processChildType');

  useDocumentTitle('FLOX - Noticeboard');

  const {
    isCreateInboundShipmentNoteFormOpen,
    setIsCreateInboundShipmentNoteFormOpen,
    handleCloseCreateInboundShipmentNoteForm,
    handleSubmitInboundShipmentNote,
  } = useInboundShipmentNote();

  const {
    inboundProcesses,
    fetchInboundSupplyChainProcesses,
    hasMoreInboundData,
    isLoadingInboundProcessesError,
    selectedInboundProcess,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    getInboundScrollParent,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    selectedProcessChildType,
    setSelectedProcessChildType,
  } = useInboundProcess();

  const {
    outboundProcesses,
    fetchOutboundSupplyChainProcesses,
    hasMoreOutboundData,
    isLoadingOutboundProcessesError,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    getOutboundScrollParent,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  } = useOutboundProcess();

  const {
    fetchInvoices,
    fetchPaidInvoices,
    billingCycles,
    paidBillingCycles,
    hasMoreInvoiceData,
    hasMorePaidInvoiceData,
    isLoadingInvoicesError,
    isLoadingPaidInvoicesError,
    getInvoiceScrollParent,
    getPaymentsScrollParent,
  } = useInvoices();

  const {
    isCreateDespatchNoteFormOpen,
    setIsCreateDespatchNoteFormOpen,
    handleCloseCreateDespatchNoteForm,
    handleSubmitDespatchNote,
  } = useDespatchNote();

  const clearProcesses = () => {
    setSelectedInboundProcess(null);
    setSelectedOutboundProcess(null);
    setSelectedInboundProcessId('');
    setSelectedOutboundProcessId('');
    setIsInboundSidebarOpen(false);
    setIsOutboundSidebarOpen(false);
  };

  useEffect(() => {
    clearProcesses();
    if (!inboundProcesses && !outboundProcesses) return;
    if (supplyChainProcessId) {
      const inboundProcess = inboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );
      const outboundProcess = outboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );

      if (inboundProcess) {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsInboundSidebarOpen(true);
          setIsViewShipmentNoteDialogOpen(true);
        }
      } else if (outboundProcess) {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsOutboundSidebarOpen(true);
          setIsViewDespatchNoteDialogOpen(true);
        }
      }
    }
  }, [
    supplyChainProcessId,
    processChildType,
    inboundProcesses,
    outboundProcesses,
  ]);

  return (
    <View>
      <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
        Noticeboard
      </Heading>
      <WarehouseSelector />
      <Board>
        {/* Inbound */}
        <BoardLane>
          <BoardLaneHeader>
            <BoardLaneTitleContainer>
              <BoardLaneTitleButton>Inbound shipments</BoardLaneTitleButton>
              <Button
                variant='contained'
                size='small'
                startIcon={<AddIcon />}
                sx={{ textTransform: 'none' }}
                onClick={() => setIsCreateInboundShipmentNoteFormOpen(true)}
              >
                Add
              </Button>
            </BoardLaneTitleContainer>
            <BoardLaneItemCount itemCount={inboundProcesses.length} />
          </BoardLaneHeader>
          <InfiniteScroll
            element={InboundSupplyChainProcessEventList as unknown as ReactNode}
            initialLoad
            loadMore={fetchInboundSupplyChainProcesses}
            hasMore={
              hasMoreInboundData === null ||
              (hasMoreInboundData === true && !isLoadingInboundProcessesError)
            }
            useWindow={false}
            getScrollParent={getInboundScrollParent}
            loader={
              <InfiniteScrollLoading
                key='inbound-loader'
                loadingMessage='Loading inbound items...'
              />
            }
          >
            {inboundProcesses.length > 0 &&
              inboundProcesses.map((inboundProcess) => (
                <SupplyChainProcessEventCard
                  key={inboundProcess.id}
                  inboundProcess={inboundProcess}
                  onClick={() => {
                    setSelectedInboundProcess(inboundProcess);
                    setSelectedInboundProcessId(inboundProcess.id);
                    setIsInboundSidebarOpen(true);
                    if (isOutboundSidebarOpen) {
                      setIsOutboundSidebarOpen(false);
                    }
                  }}
                />
              ))}
            {hasMoreInboundData === false && (
              <InfiniteScrollEndMessage
                isVisible={inboundProcesses.length > 25}
                direction='column'
                endMessageText='End of inbound items'
                handleScrollToTop={handleScrollToTopOfInbound}
              />
            )}
            {isLoadingInboundProcessesError && (
              <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your inbound items.' />
            )}
          </InfiniteScroll>
        </BoardLane>
        {/* Outbound */}
        <BoardLane>
          <BoardLaneHeader>
            <BoardLaneTitleContainer>
              <BoardLaneTitleButton>Outbound shipments</BoardLaneTitleButton>
              <Button
                variant='contained'
                size='small'
                startIcon={<AddIcon />}
                sx={{ textTransform: 'none' }}
                onClick={() => setIsCreateDespatchNoteFormOpen(true)}
              >
                Add
              </Button>
            </BoardLaneTitleContainer>
            <BoardLaneItemCount itemCount={outboundProcesses.length} />
          </BoardLaneHeader>
          <InfiniteScroll
            element={
              OutboundSupplyChainProcessEventList as unknown as ReactNode
            }
            initialLoad
            loadMore={fetchOutboundSupplyChainProcesses}
            hasMore={
              hasMoreOutboundData === null ||
              (hasMoreOutboundData === true && !isLoadingOutboundProcessesError)
            }
            useWindow={false}
            getScrollParent={getOutboundScrollParent}
            loader={
              <InfiniteScrollLoading
                key='outbound-loader'
                loadingMessage='Loading outbound items...'
              />
            }
          >
            {outboundProcesses.length > 0 &&
              outboundProcesses.map((outboundProcess) => (
                <SupplyChainProcessEventCard
                  key={outboundProcess.id}
                  outboundProcess={outboundProcess}
                  onClick={() => {
                    setSelectedOutboundProcess(outboundProcess);
                    setSelectedOutboundProcessId(outboundProcess.id);
                    setIsOutboundSidebarOpen(true);
                    if (isInboundSidebarOpen) {
                      setIsInboundSidebarOpen(false);
                    }
                  }}
                />
              ))}
            {hasMoreOutboundData === false && (
              <InfiniteScrollEndMessage
                isVisible={outboundProcesses.length > 25}
                direction='column'
                endMessageText='End of outbound items'
                handleScrollToTop={handleScrollToTopOfOutbound}
              />
            )}
            {isLoadingInboundProcessesError && (
              <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your outbound items.' />
            )}
          </InfiniteScroll>
        </BoardLane>
        {user?.roles.includes('FinanceAdmin') && (
          <>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Invoices</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={billingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchInvoices}
                hasMore={
                  hasMoreInvoiceData === null ||
                  (hasMoreInvoiceData === true && !isLoadingInvoicesError)
                }
                useWindow={false}
                getScrollParent={getInvoiceScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='invoice-loader'
                    loadingMessage='Loading invoice items...'
                  />
                }
              >
                {billingCycles.length > 0 &&
                  billingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.floxToShipperInvoice}
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMoreInboundData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={billingCycles.length > 25}
                    direction='column'
                    endMessageText='End of invoices'
                    handleScrollToTop={() =>
                      getInvoiceScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your invoices.' />
                )}
              </InfiniteScroll>
            </BoardLane>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Payments</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={paidBillingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchPaidInvoices}
                hasMore={
                  hasMorePaidInvoiceData === null ||
                  (hasMorePaidInvoiceData === true &&
                    !isLoadingPaidInvoicesError)
                }
                useWindow={false}
                getScrollParent={getPaymentsScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='payments-loader'
                    loadingMessage='Loading payments...'
                  />
                }
              >
                {paidBillingCycles.length > 0 &&
                  paidBillingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.floxToShipperInvoice}
                      payment
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMorePaidInvoiceData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={paidBillingCycles.length > 25}
                    direction='column'
                    endMessageText='End of payments'
                    handleScrollToTop={() =>
                      getPaymentsScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingPaidInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your payments.' />
                )}
              </InfiniteScroll>
            </BoardLane>
          </>
        )}
        {/* Temporary empty board lanes until we define them */}
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
      </Board>
      <div id='widgetRoot' />
      {/* Sidebar for selected inbound process */}
      {selectedInboundProcess && (
        <WarehouseClientInboundSideDrawer
          selectedInboundProcess={selectedInboundProcess}
          setSelectedInboundProcess={setSelectedInboundProcess}
          isOpen={isInboundSidebarOpen}
          setIsOpen={setIsInboundSidebarOpen}
          isDesktop={isDesktop}
          selectedInboundProcessHasDescription={
            selectedInboundProcessHasDescription
          }
          setIsViewShipmentNoteDialogOpen={setIsViewShipmentNoteDialogOpen}
          setIsViewGoodsReceivedNoteDialogOpen={
            setIsViewGoodsReceivedNoteDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* Sidebar for selected outbound process */}
      {selectedOutboundProcess && (
        <WarehouseClientOutboundSideDrawer
          selectedOutboundProcess={selectedOutboundProcess}
          setSelectedOutboundProcess={setSelectedOutboundProcess}
          isOpen={isOutboundSidebarOpen}
          setIsOpen={setIsOutboundSidebarOpen}
          isDesktop={isDesktop}
          selectedOutboundProcessHasDescription={
            selectedOutboundProcessHasDescription
          }
          setIsViewDespatchNoteDialogOpen={setIsViewDespatchNoteDialogOpen}
          setIsViewPickingConfirmationDialogOpen={
            setIsViewPickingConfirmationDialogOpen
          }
          setIsViewOutboundShipmentNoteDialogOpen={
            setIsViewOutboundShipmentNoteDialogOpen
          }
          setIsViewProofOfDeliveryDialogOpen={
            setIsViewProofOfDeliveryDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* View shipment note */}
      <DialogModal
        open={isViewShipmentNoteDialogOpen}
        setOpen={setIsViewShipmentNoteDialogOpen}
        onClose={handleCloseViewShipmentNote}
        title='Shipment Note'
        id='view-shipment-note-title'
      >
        <ShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create inbound shipment note form */}
      <DialogModal
        open={isCreateInboundShipmentNoteFormOpen}
        setOpen={setIsCreateInboundShipmentNoteFormOpen}
        onClose={handleCloseCreateInboundShipmentNoteForm}
        title='Create Inbound Shipment Note'
        id='create-inbound-shipment-note-form-title'
      >
        <CreateInboundShipmentNoteForm
          onClose={() => {
            setIsCreateInboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitInboundShipmentNote}
        />
      </DialogModal>
      {/* View signed-off goods received note */}
      <DialogModal
        open={isViewGoodsReceivedNoteDialogOpen}
        setOpen={setIsViewGoodsReceivedNoteDialogOpen}
        onClose={handleCloseViewGoodsReceivedNote}
        title='Goods Received Note'
        id='view-goods-received-note-form-title'
      >
        <GoodsReceivedNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsViewGoodsReceivedNoteDialogOpen(false);
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create despatch note */}
      <DialogModal
        open={isCreateDespatchNoteFormOpen}
        setOpen={setIsCreateDespatchNoteFormOpen}
        onClose={handleCloseCreateDespatchNoteForm}
        title='Create Despatch Note'
        id='despatch-note-form-title'
      >
        <CreateDespatchNoteForm
          onClose={() => {
            setIsCreateDespatchNoteFormOpen(false);
          }}
          onSubmit={handleSubmitDespatchNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View despatch note details */}
      <DialogModal
        open={isViewDespatchNoteDialogOpen}
        setOpen={setIsViewDespatchNoteDialogOpen}
        onClose={handleCloseViewDespatchNote}
        title='Despatch Note'
        id='view-despatch-note-form-title'
      >
        <DespatchNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewDespatchNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View picking confirmation */}
      <DialogModal
        open={isViewPickingConfirmationDialogOpen}
        setOpen={setIsViewPickingConfirmationDialogOpen}
        onClose={handleCloseViewPickingConfirmation}
        title='Picking Confirmation'
        id='view-picking-confirmation-form-title'
      >
        <PickingConfirmationDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewPickingConfirmationDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View outbound shipment note */}
      <DialogModal
        open={isViewOutboundShipmentNoteDialogOpen}
        setOpen={setIsViewOutboundShipmentNoteDialogOpen}
        onClose={handleCloseViewOutboundShipmentNote}
        title='Outbound Shipment Note'
        id='view-outbound-shipment-note-form-title'
      >
        <OutboundShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewOutboundShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View proof of delivery */}
      <DialogModal
        open={isViewProofOfDeliveryDialogOpen}
        setOpen={setIsViewProofOfDeliveryDialogOpen}
        onClose={handleCloseViewProofOfDelivery}
        title='Proof of Delivery'
        id='view-proof-of-delivery-form-title'
      >
        <ProofOfDeliveryDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewProofOfDeliveryDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
    </View>
  );
}

WarehouseClientNoticeboard.displayName = 'WarehouseClientNoticeboard';
